/* import __COLOCATED_TEMPLATE__ from './realtime-updates.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type NexusEvent, type UserMergedEvent } from 'embercom/services/nexus';
import type Nexus from 'embercom/services/nexus';
import { NexusStatusEvents } from 'embercom/services/nexus';
import type Conversation from 'embercom/objects/inbox/conversation';
import type InboxState from 'embercom/services/inbox-state';
import { InboxEvents } from 'embercom/services/inbox-state';
import { action } from '@ember/object';
import { type RenderableDataWithSendState } from 'embercom/objects/inbox/send-state';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'ember-intl/services/intl';
import type Session from 'embercom/services/session';

interface Args {
  conversation: Conversation;
  onUpdateReceived: (clientAssignedUuid?: string) => void;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationRealtimeUpdatesComponent extends Component<Signature> {
  @service declare nexus: Nexus;
  @service declare inboxState: InboxState;
  @service declare snackbar: Snackbar;
  @service intl!: IntlService;
  @service declare session: Session;

  nexusReconnectHandler: () => void;
  localEventHandler: (c: Conversation) => void;

  conversationId?: number;

  constructor(owner: any, args: Args) {
    super(owner, args);

    this.nexusReconnectHandler = () => {
      this.args.onUpdateReceived();
    };
    this.localEventHandler = this.handleLocalConversationEvent.bind(this);

    this.subscribe();
  }

  willDestroy() {
    super.willDestroy();
    this.unsusbscribe();
  }

  subscribe() {
    this.inboxState.on(InboxEvents.ConversationUpdated, this, 'localEventHandler');
    this.nexus.on(NexusStatusEvents.Reconnected, this.nexusReconnectHandler);
  }

  unsusbscribe() {
    this.nexus.off(NexusStatusEvents.Reconnected, this.nexusReconnectHandler);
    this.inboxState.off(InboxEvents.ConversationUpdated, this, 'localEventHandler');
  }

  @action
  handleConversationEvent(event: NexusEvent) {
    let eventData = event.eventData as { conversationId: string; clientAssignedUuid?: string };
    let id = Number(eventData.conversationId);
    if (id === this.args.conversation.id) {
      this.args.onUpdateReceived(eventData.clientAssignedUuid);
    }
  }

  private handleLocalConversationEvent(conversation: Conversation) {
    if (conversation.id === this.args.conversation.id) {
      this.args.onUpdateReceived();
    }
  }

  @action
  handleTeammateLinkDomainPoliciesUpdated(_e: NexusEvent) {
    this.session.workspace.reloadAppSettings();
  }

  @action
  handleConversationSeenEvent(event: NexusEvent) {
    let eventData = event.eventData as { conversationId: string };
    let id = Number(eventData.conversationId);

    if (id === this.args.conversation.id) {
      this.args.conversation.updateLastSeenByUserAt();
    }
  }

  @action
  handleUserMergedEvent(event: UserMergedEvent) {
    let eventData = event.eventData;
    if (this.args.conversation.user.id === eventData.leadId) {
      this.args.onUpdateReceived();
      this.snackbar.notify(
        this.intl.t('inbox.conversation-details-sidebar.potential-duplicates.user-merged-success'),
      );
    }
  }

  @action
  handleExternalReplySendStateUpdated(event: NexusEvent) {
    let eventData = event.eventData as {
      conversationId: string;
      commentId: string;
      sendState: number;
      infoMessage: string;
    };

    if (Number(eventData.conversationId) !== this.args.conversation?.id) {
      return;
    }

    let conversationPart = this.args.conversation.renderableParts.find(
      (part) => part.entityId === Number(eventData.commentId),
    );
    if (conversationPart) {
      let adminComment = conversationPart.renderableData as RenderableDataWithSendState;

      adminComment.updateSendState({
        sendState: eventData.sendState,
        infoMessage: eventData.infoMessage,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Conversation::RealtimeUpdates': typeof Inbox2ConversationRealtimeUpdatesComponent;
    'inbox2/conversation/realtime-updates': typeof Inbox2ConversationRealtimeUpdatesComponent;
  }
}
