/* import __COLOCATED_TEMPLATE__ from './call-timer.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { registerDestructor } from '@ember/destroyable';
import moment from 'moment-timezone';

interface Args {
  startTime?: number;
}

export default class CallTimer extends Component<Args> {
  @tracked currentTime = moment().valueOf();

  constructor(owner: unknown, args: any) {
    super(owner, args);

    let timer = setInterval(() => (this.currentTime = moment().valueOf()), 1000);

    registerDestructor(this, () => {
      clearInterval(timer);
    });
  }

  get formattedTime() {
    if (!this.args.startTime) {
      return '00:00';
    }

    let duration = moment.duration(
      Math.abs(this.currentTime - this.args.startTime),
      'milliseconds',
    );

    let minutes = Math.floor(duration.asMinutes());
    let seconds = Math.floor(duration.seconds());

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CallTimer': typeof CallTimer;
    'inbox2/call-timer': typeof CallTimer;
  }
}
