/* import __COLOCATED_TEMPLATE__ from './call-modal.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';

import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type PhoneNumber from 'embercom/models/inbox/phone-number';
import type Conversation from 'embercom/objects/inbox/conversation';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type IntercomCallService from 'embercom/services/intercom-call-service';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import type CallParticipantSummary from 'embercom/objects/inbox/callings/call-participant-summary';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';

interface Args {
  user: UserSummary | undefined;
  participants: CallParticipantSummary[];
  isConferenceCallActive: boolean;
  addingParticipantLabel?: string;
  isMicrophoneOn: boolean;
  isRecordingEnabled: boolean;
  renderOnHold: boolean;
  canToggleHold: boolean;
  canToggleRecording: boolean;
  renderTransfer: boolean;
  renderWarmTransfer: boolean;
  canTransfer: boolean;
  renderAddParticipant: boolean;
  canAddParticipant: boolean;
  isTimerVisible: boolean;
  isOnHold: boolean;
  isTransferring: boolean;
  isRecording: boolean;
  workspacePhoneNumber: string | undefined;
  calledNumberCountryCode: string | undefined;
  isCallRinging?: boolean;
  isCallEnded?: boolean;
  startTime?: number;
  conversation?: Conversation;
  isWarmTransferCallActive?: boolean;
  warmTransferTeammate?: AdminSummary;
  warmTransferStartTime?: number;
  completeWarmTransfer: any;
  endCall: any;
  toggleWarmTransferCall: any;
  isIncomingCallWarmTransfer?: boolean;
  oldAdmin?: AdminSummary;
  warmTransferUser?: UserSummary;
  leaveCall: () => void;
  pressDigit: (digit: string) => void;
  toggleMicrophone: () => void;
  toggleOnHold: () => void;
  toggleRecording: () => void;
}

export default class CallModal extends Component<Args> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare intercomCallService: IntercomCallService;
  @service declare intercomEventService: any;
  @service declare router: RouterService;

  @tracked usingKeypad = false;
  @tracked keypadInput = '';
  @tracked workspacePhoneNumber?: PhoneNumber;
  @tracked isCollapsed = false;
  @tracked isInvisible = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.getWorkspacePhoneNumber();
  }

  async getWorkspacePhoneNumber() {
    if (this.args.workspacePhoneNumber) {
      this.workspacePhoneNumber = this.intercomCallService.workspacePhoneNumbers.findBy(
        'phoneNumber',
        this.args.workspacePhoneNumber.replace(/ /g, ''),
      );
    }
  }

  get currentPhoneNumberTitle() {
    return this.intl.t('calling.modal.current-phone-number', {
      number: this.args.workspacePhoneNumber,
      country: this.args.calledNumberCountryCode,
    });
  }

  @action pressDigit(digit: string) {
    this.keypadInput += digit;
    this.args.pressDigit(digit);
  }

  get isWarmTransferCall() {
    return this.args.warmTransferTeammate || this.args.warmTransferUser;
  }

  get selectedPhoneNumberText() {
    return this.workspacePhoneNumber?.name || this.workspacePhoneNumber?.phoneNumber;
  }

  get callTitle() {
    if (this.args.isConferenceCallActive) {
      return this.intl.t('calling.modal.title.conference-call');
    }

    if (this.args.isWarmTransferCallActive) {
      if (this.args.warmTransferUser) {
        return this.args.warmTransferUser.name || this.args.warmTransferUser.phone;
      }

      return this.args.warmTransferTeammate?.name;
    }

    if (this.args.isIncomingCallWarmTransfer) {
      return this.args.oldAdmin?.name;
    }

    return (
      this.args.user?.name || this.args.user?.phone || this.intl.t('calling.modal.unknown-number')
    );
  }

  get onHoldCallTitle() {
    if (this.args.isWarmTransferCallActive) {
      return (
        this.args.user?.name || this.args.user?.phone || this.intl.t('calling.modal.unknown-number')
      );
    }

    if (this.args.warmTransferUser) {
      return this.args.warmTransferUser.name || this.args.warmTransferUser.phone;
    }

    return this.args.warmTransferTeammate?.name;
  }

  @action goToConversation() {
    if (!this.args.conversation) {
      return;
    }

    return this.router.transitionTo(
      'inbox.workspace.inbox.conversation.conversation',
      this.args.conversation.id,
    );
  }

  @action showKeypad() {
    this.usingKeypad = true;
    this.intercomCallService.registerCallEvent({
      action: 'opened',
      object: 'keypad',
      place: 'calling',
    });
  }

  @action
  async showTransferOptions() {
    this.isInvisible = true;
    this.intercomCallService.openDialler({
      diallerContext: 'transfer',
      returnToCallAction: this.returnToCall.bind(this),
      analyticsConfig: {
        place: 'transfer-call',
      },
    });
  }

  @action showWarmTransferOptions() {
    this.isInvisible = true;
    this.intercomCallService.openDialler({
      diallerContext: 'warm-transfer',
      returnToCallAction: this.returnToCall.bind(this),
      analyticsConfig: {
        place: 'warm-transfer',
      },
    });
  }

  @action
  async showAddParticipantOptions() {
    this.isInvisible = true;
    this.intercomCallService.openDialler({
      diallerContext: 'conference',
      returnToCallAction: this.returnToCall.bind(this),
      analyticsConfig: {
        place: 'conference-call',
      },
    });
  }

  @action returnToCall() {
    this.isInvisible = false;
  }

  willDestroy() {
    super.willDestroy();
    this.intercomCallService.closeDialler(true);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CallModal': typeof CallModal;
    'inbox2/call-modal': typeof CallModal;
  }
}
