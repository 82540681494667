/* import __COLOCATED_TEMPLATE__ from './admin-avatar.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import { inject as service } from '@ember/service';
import { transformAvatarUrl } from 'embercom/objects/inbox/inboxes/avatars';
import type ThemeService from 'embercom/services/theme-service';

interface Args {
  admin?: AdminSummary;
  isActive?: boolean;
  isAway?: boolean;
  large?: boolean;
  small?: boolean;
  tiny?: boolean;
  outline?: boolean;
  shape?: 'squircle' | undefined;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class AdminAvatarComponent extends Component<Signature> {
  @service declare themeService: ThemeService;

  get isActive(): boolean | undefined {
    if (this.args.isActive !== undefined) {
      return this.args.isActive;
    }

    if (!this.args.admin) {
      return;
    }

    if (this.args.admin.isAway === undefined) {
      return;
    }

    return !this.args.admin.isAway;
  }

  get isAway(): boolean | undefined {
    if (this.args.isAway !== undefined) {
      return this.args.isAway;
    }

    if (!this.args.admin) {
      return;
    }

    return this.args.admin.isAway;
  }

  get imageURL(): string | undefined {
    let url = this.args.admin?.imageURL;
    if (!url) {
      return;
    }

    return transformAvatarUrl(url, this.themeService.darkModeEnabled);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::AdminAvatar': typeof AdminAvatarComponent;
  }
}
