/* import __COLOCATED_TEMPLATE__ from './request-to-upgrade.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type Router from '@ember/routing/router-service';
import type ThemeService from 'embercom/services/theme-service';

interface RequestToUpgradeArgs {
  onModalClose: () => void;
  copilotNotEnabled?: boolean;
}

interface Signature {
  Element: Element;
  Args: RequestToUpgradeArgs;
  Blocks: {
    default: [];
  };
}

export default class RequestToUpgrade extends Component<Signature> {
  @service declare router: Router;
  @service declare session: Session;
  @service declare themeService: ThemeService;

  get link(): string {
    let route = this.router.urlFor(
      'apps.app.settings.workspace.teammates',
      this.session.workspace.id,
    );
    let url = `${window.location.protocol}//${window.location.host}${route}`;
    return url;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Freemium::RequestToUpgrade': typeof RequestToUpgrade;
    'inbox2/copilot/freemium/request-to-upgrade': typeof RequestToUpgrade;
  }
}
