/* import __COLOCATED_TEMPLATE__ from './ticket-attributes.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Conversation from 'embercom/objects/inbox/conversation';
import type InboxApi from 'embercom/services/inbox-api';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
// @ts-ignore
import intermoji from '@intercom/intermoji';
import { DescriptorUtils, type TicketDescriptor } from 'embercom/objects/inbox/ticket';
import type Snackbar from 'embercom/services/snackbar';
import type TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import { useResource } from 'ember-resources';
import { TicketTypeResource } from 'embercom/components/inbox2/ticket-type-resource';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import type ConditionalAttributesService from 'embercom/services/conditional-attributes-service';

interface Args {
  conversation: Conversation;
  displayTicketId: boolean;
}

interface Signature {
  Args: Args;
}

export default class TicketAttributes extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare snackbar: Snackbar;
  @service declare conditionalAttributesService: ConditionalAttributesService;

  @trackedReset('args.conversation.id') ticketTypeId: number | undefined;

  readonly ticketTypeResource = useResource(this, TicketTypeResource, () => ({
    ticketTypeId: this.args.conversation.ticketType?.id,
    conversation: this.args.conversation,
  }));

  get emojiUrl(): string {
    return intermoji.twemojiSVGUri(this.args.conversation?.ticketType?.emoji ?? '');
  }

  get conditionalAttributesEvaluator() {
    let ticketTypes = this.session.workspace.ticketTypes.value;

    let ticketType = ticketTypes?.filter(
      (ticketType: any) => ticketType.id === this.args.conversation.ticketType?.id,
    )[0];

    if (ticketType?.descriptors && ticketType.descriptors?.length > 0) {
      return this.conditionalAttributesService.initialize({
        conversation: this.args.conversation,
        descriptors: ticketType.descriptors,
      });
    }

    return undefined;
  }

  get ticketAttributes() {
    let ticketTypeId = this.args.conversation.ticketType?.id;
    let ticketTypes = this.session.workspace.ticketTypes.value;

    return DescriptorUtils.getVisibleAttributes(
      ticketTypeId,
      ticketTypes,
      this.ticketTypeResource,
      this.conditionalAttributesEvaluator,
      this.conversationAttributesById,
      this.session.workspace.canUseTicketsConditionalAttributes,
    );
  }

  get ticketIdValue() {
    return `${this.args.conversation.ticketId}` || `—`;
  }

  get linkedTicketIdValue() {
    return this.args.conversation.ticketId ? `#${this.ticketIdValue}` : this.ticketIdValue;
  }

  get conversationAttributesById() {
    if (!this.args.conversation) {
      return {};
    }

    return this.args.conversation.attributes.reduce(
      (byIds, attribute) => {
        byIds[attribute.descriptor.id] = attribute;
        return byIds;
      },
      {} as Record<string, ConversationAttributeSummary>,
    );
  }

  @action async onUpdateTicketAttribute(
    attribute: TicketAttributeSummary,
    descriptor?: TicketDescriptor,
  ) {
    if (descriptor?.requiredToCreate && attribute.isEmpty) {
      this.snackbar.notify(this.intl.t('inbox.ticket-attributes.attribute-required'), {
        clearable: true,
      });
      attribute.rollback();
      return;
    }

    if (this.ticketTypeId || !attribute.isUpdated) {
      return;
    }

    if (
      this.session.workspace.canUseOptimisticUpdates ||
      this.session.workspace.canUseTicketsConditionalAttributes
    ) {
      await this.conditionalAttributesEvaluator?.updateAttribute(
        attribute as unknown as ConversationAttributeSummary,
      );
    } else {
      return this.inboxApi.updateAttribute(this.args.conversation?.id, attribute);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPage::TicketPanel::TicketAttributes': typeof TicketAttributes;
    'inbox2/conversation-page/ticket-panel/ticket-attributes': typeof TicketAttributes;
  }
}
