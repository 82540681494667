/* import __COLOCATED_TEMPLATE__ from './conversation-tags-updated.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type ConversationTagsUpdated from 'embercom/objects/inbox/renderable/conversation-tags-updated';

interface ConversationTagsUpdatedPart extends RenderablePart {
  renderableData: ConversationTagsUpdated;
}

interface Args {
  part: ConversationTagsUpdatedPart;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamEventPartsConversationTagsUpdatedComponent extends Component<Signature> {
  @service declare intl: IntlService;

  get renderableData() {
    return this.args.part.renderableData;
  }

  get noTagsAdded() {
    return this.renderableData.tagsAdded === null || this.renderableData.tagsAdded.length === 0;
  }

  get noTagsRemoved() {
    return this.renderableData.tagsRemoved === null || this.renderableData.tagsRemoved.length === 0;
  }

  get tagsAddedText(): string {
    if (this.noTagsAdded) {
      return '';
    }
    let tagsToBeAdded = this.renderableData.tagsAdded.map((tag) => `"${tag.name}"`).join(', ');
    let includeAuthor = this.noTagsRemoved;

    return this.intl.t('inbox.conversation-stream.conversation-tags.added', {
      tags: tagsToBeAdded,
      name: includeAuthor ? this.renderableData.taggingEntity.name : undefined,
      count: this.renderableData.tagsAdded.length,
    });
  }

  get tagsRemovedText(): string {
    if (this.noTagsRemoved) {
      return '';
    }
    let tagsToBeRemoved = this.renderableData.tagsRemoved.map((tag) => `"${tag.name}"`).join(', ');
    let translationKey = 'inbox.conversation-stream.conversation-tags.removed';
    let name = this.renderableData.taggingEntity.name;
    let count = this.renderableData.tagsRemoved.length;

    if (!this.noTagsAdded) {
      translationKey = 'inbox.conversation-stream.conversation-tags.removed-lowercase';
    }

    return this.intl.t(translationKey, {
      tags: tagsToBeRemoved,
      name,
      count,
    });
  }

  get textToDisplay() {
    let tagsAdded = this.tagsAddedText;
    let tagsRemoved = this.tagsRemovedText;

    if (this.noTagsAdded) {
      return tagsRemoved;
    }
    if (this.noTagsRemoved) {
      return tagsAdded;
    }

    return `${this.tagsAddedText}, ${this.intl.t('inbox.common.and')} ${this.tagsRemovedText}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::ConversationTagsUpdated': typeof Inbox2ConversationStreamEventPartsConversationTagsUpdatedComponent;
    'inbox2/conversation-stream/event-parts/conversation-tags-updated': typeof Inbox2ConversationStreamEventPartsConversationTagsUpdatedComponent;
  }
}
