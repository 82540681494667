/* import __COLOCATED_TEMPLATE__ from './results-panel.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type CommandKService from 'embercom/services/command-k';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxState from 'embercom/services/inbox-state';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'embercom/services/intl';
import { useResource } from 'ember-resources';
import NavigableSelection from '../../common/navigable-selection-resource';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import { type TicketCategory } from 'embercom/objects/inbox/ticket';
import type ThemeService from 'embercom/services/theme-service';

interface Args {
  onSelect: (s: ChildAction, a?: object) => void;
  query: string;
  items: Array<ChildAction>;
  ticketCategory: TicketCategory;
}

interface Signature {
  Args: Args;
}

export default class SearchTicketResultsPanelComponent extends Component<Signature> {
  @service declare themeService: ThemeService;
  @service declare commandK: CommandKService;
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare intl: IntlService;

  @service declare snackbar: Snackbar;

  selection = useResource(this, NavigableSelection, () => ({
    items: this.args.items,
    isCommandKVisible: this.commandK.isVisible,
  }));

  @action
  async onSelect(ticket: any) {
    this.args.onSelect(ticket);
  }

  get loadingIndicatorColor(): string {
    return this.themeService.darkModeEnabled ? 'white' : 'blue';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::SearchTicket::ResultsPanel': typeof SearchTicketResultsPanelComponent;
    'inbox2/command-k/search-ticket/results-panel': typeof SearchTicketResultsPanelComponent;
  }
}
