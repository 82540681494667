/* import __COLOCATED_TEMPLATE__ from './recipient-manager.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { ReplyChannelType, type ReplyChannel } from 'embercom/objects/inbox/composer-pane';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import { participantObjectToUserSummary } from 'embercom/objects/inbox/user-summary';
import type { NewConversation } from 'embercom/objects/inbox/conversation';
import type Conversation from 'embercom/objects/inbox/conversation';
import { type Recipients } from '../conversation-reply-composer';
import { Channel } from 'embercom/models/data/inbox/channels';
import { trackedReset } from 'tracked-toolbox';
import { uniqBy } from 'embercom/helpers/uniq-by';
import { isPresent } from '@ember/utils';

interface RecipientManagerArgs {
  onBlur?: () => void;
  conversation: Conversation | NewConversation;
  recipients: Recipients;
  originalRecipients: Recipients;
  setRecipientErrors: (
    recipientsWithErrors: { recipient: UserSummary; recipientError: string | void }[],
  ) => void;
  isCreatingConversation?: boolean;
  replyChannel: ReplyChannel;
  replyToData?: Array<UserSummary>;
  isSideConversationComposer?: boolean;
  onRecipientChange: (recipientsList: Recipients) => void;
}

export default class RecipientManagerComponent extends Component<RecipientManagerArgs> {
  @service declare session: Session;
  @trackedReset('args.recipients') ccRecipients: Recipients['cc'] = this.args.recipients?.cc || {
    ids: [],
    emails: [],
  };
  @trackedReset('args.recipients') toRecipients: Recipients['to'] = this.args.recipients?.to || {
    ids: [],
    emails: [],
  };
  @trackedReset('args.conversation') showCc = this.showCcByDefault();
  @tracked focusStates = {
    cc: false,
  };

  get allToRecipients(): UserSummary[] {
    return this.getFlatRecipients(this.toRecipients);
  }

  get allCcRecipients(): UserSummary[] {
    return this.getFlatRecipients(this.ccRecipients);
  }

  get originalToRecipients(): UserSummary[] | [] {
    return this.getFlatRecipients(this.args.originalRecipients?.to);
  }

  get originalCcRecipients(): UserSummary[] | [] {
    return this.getFlatRecipients(this.args.originalRecipients?.cc);
  }

  get ccEnabled(): boolean {
    return (
      this.isEmailCurrentChannel && this.session.workspace.isFeatureEnabled('team-channels-cc')
    );
  }

  getFlatRecipients(recipients: Recipients['to'] | Recipients['cc'] | undefined): UserSummary[] {
    let recipientsList = recipients
      ? [...(recipients.ids || []), ...(recipients.emails || [])]
      : [];

    if (!recipientsList.length) {
      return [] as UserSummary[];
    }

    return recipientsList.map((recipient) => participantObjectToUserSummary(recipient));
  }

  showCcByDefault() {
    let anyExistingCcRecipients = this.allCcRecipients.length > 0;
    let anyOriginalCcRecipients = this.originalCcRecipients.length > 0;
    return this.isEmailCurrentChannel && (anyExistingCcRecipients || anyOriginalCcRecipients);
  }

  updateRecipients() {
    if (!this.args.onRecipientChange) {
      return;
    }

    let all = uniqBy(
      [...this.allToRecipients, ...this.allCcRecipients],
      (recipient: UserSummary) => (isPresent(recipient.email) ? recipient.email : recipient.id),
    );

    let updatedRecipients: Recipients = {
      all,
      to: this.toRecipients,
      cc: this.ccRecipients,
    };

    this.args.onRecipientChange(updatedRecipients);
  }

  enableFocusState(field: keyof typeof this.focusStates) {
    this.focusStates[field] = true;
    Object.keys(this.focusStates).forEach((key) => {
      if (key !== field) {
        this.focusStates[key as keyof typeof this.focusStates] = false;
      }
    });
  }

  get isEmailCurrentChannel() {
    let currentChannel = (this.args.conversation as Conversation)?.channel.current;
    return currentChannel
      ? currentChannel === Channel.Email
      : this.args.replyChannel.type === ReplyChannelType.Email;
  }

  @action
  onToRecipientChange(recipientsList: UserSummary[]) {
    this.toRecipients = {
      ids: recipientsList.filter((recipient) => recipient.id),
      emails: recipientsList.filter((recipient) => recipient.email && !recipient.id),
    };

    this.updateRecipients();
  }

  @action
  onCcRecipientChange(recipientsList: UserSummary[]) {
    this.ccRecipients = {
      ids: recipientsList.filter((recipient) => recipient.id),
      emails: recipientsList.filter((recipient) => recipient.email && !recipient.id),
    };

    this.updateRecipients();
  }

  @action
  triggerCc() {
    this.enableFocusState('cc');
    this.showCc = true;
  }

  @action
  handleBlur(): void {
    if (this.args.onBlur) {
      this.args.onBlur();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::RecipientManager': typeof RecipientManagerComponent;
    'inbox2/composer/recipient-manager': typeof RecipientManagerComponent;
  }
}
