/* import __COLOCATED_TEMPLATE__ from './attachments.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { type Attachment } from '@intercom/interblocks.ts';
import { type Upload } from 'embercom/objects/inbox/renderable/upload';

type AnyAttachment = Upload | Attachment;

interface Args {
  attachments: AnyAttachment[];
}

interface Signature {
  Args: Args;
}

const INLINE_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/tiff'];

export default class Attachments extends Component<Signature> {
  get attachmentsWithoutImages(): AnyAttachment[] {
    return this.args.attachments.filter((el) => !this.isImage(el));
  }

  get images(): AnyAttachment[] {
    return this.args.attachments.filter(this.isImage);
  }

  private isImage(attachment: Upload | Attachment) {
    return INLINE_IMAGE_TYPES.includes(attachment.contentType);
  }

  get hasOneAudioUpload(): boolean {
    return this.args.attachments.length === 1 && this.isAudioFile(this.args.attachments[0]);
  }

  private isAudioFile(upload: AnyAttachment): boolean {
    return upload.name.endsWith('.oga') || upload.name.endsWith('.ogg');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::Attachments': typeof Attachments;
    'inbox2/conversation-stream/part-groups/attachments': typeof Attachments;
  }
}
