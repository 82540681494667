/* import __COLOCATED_TEMPLATE__ from './canvas.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { type CanvasContent, CanvasView } from '@intercom/interblocks.ts';
import $ from 'jquery';
import type Session from 'embercom/services/session';
import {
  type CanvasShape,
  type TextComponent,
} from '@intercom/interblocks.ts/dist/types/parse/v1/canvas';
import { isEqual } from 'underscore';
import { LOADING_CANVAS } from 'embercom/objects/inbox/canvas-view-loader';
import { request } from 'embercom/lib/inbox/requests';

export const RETRIES = 2;

export const ERROR_COMPONENT: TextComponent = {
  type: 'text',
  style: 'error',
  text: `Messenger App couldn't be loaded`,
};

type Canvas = CanvasShape & CanvasContent;

interface Args {
  canvas: Canvas;
  styleContext: string;
  disableInputs: boolean;
  conversationId: string;
  location: string;
  onUrlAction?: () => unknown;
  onSubmitAction?: () => unknown;
  autoFocus?: boolean;
  confineDropdownToCanvas?: boolean;
}

interface Signature {
  Args: Args;
}

function isSameCanvas(a?: Canvas, b?: Canvas) {
  return a && b && isEqual(a, b);
}

export default class Inbox2AppFrameworkCanvas extends Component<Signature> {
  @service declare session: Session;

  @tracked canvasView?: CanvasView;
  @tracked private localCanvas?: Canvas;

  get stylePrefix(): string {
    if (this.args.styleContext === 'inbox-apps') {
      return 'intercom-interblocks-inbox-card';
    }

    return 'intercom-interblocks-messenger-card  ';
  }

  @action renderCanvas(element: HTMLElement) {
    if (this.localCanvas && isSameCanvas(this.localCanvas, this.args.canvas)) {
      return;
    }

    let canvas = this.args.canvas;
    this.localCanvas = { ...canvas };

    if (this.args.disableInputs && canvas.content?.components) {
      canvas.content.components = this.addDisabledAttributeToInputs(canvas.content.components);
    }

    if (!this.canvasView) {
      this.canvasView = new CanvasView(
        element,
        this.canvasNetworkingClient,
        this.urlAction,
        this.args.onSubmitAction,
        this.args.autoFocus,
        this.args.confineDropdownToCanvas,
        LOADING_CANVAS,
      );
    }

    this.canvasView.render(canvas);
  }

  willDestroy(): void {
    super.willDestroy();
    this.canvasView?.destroy();
    this.canvasView = undefined;
  }

  private addDisabledAttributeToInputs(components: Canvas['components']) {
    return components.map((component: any) => {
      if (component.type === 'input') {
        component.disabled = true;
      }

      return component;
    });
  }

  private get urlAction() {
    return this.args.onUrlAction ?? safeWindowOpen;
  }

  private async fetchContentWithRetry(retries: number): Promise<CanvasContent> {
    let result: { content: CanvasContent };
    let contextQueryParamString = this.buildQueryParamString();

    try {
      let response = await request(
        `/ember/messenger_cards/content/${this.args.canvas.id}.json?${contextQueryParamString}`,
      );
      result = await response.json();
    } catch (err) {
      if (retries) {
        return this.fetchContentWithRetry(--retries);
      }
      console.error(`card with ${this.args.canvas.id} id failed to load, error:`, err);
      result = {
        content: {
          version: '0.1',
          components: [ERROR_COMPONENT],
        },
      };
    }

    return result.content;
  }

  private buildQueryParamString() {
    let params = new URLSearchParams({
      app_id: this.session.workspace.id,
      conversation_id: this.args.conversationId,
      location: this.args.location,
    });

    return params.toString();
  }

  private get canvasNetworkingClient() {
    return {
      get: async (_: string, done: (content: CanvasContent) => void) => {
        let content = await this.fetchContentWithRetry(RETRIES);
        done(content);
      },

      post: (url: string, done: (content: CanvasContent) => void) => {
        // eslint-disable-next-line ember/no-jquery
        $.post(`/ember/${url}`).done(done);
      },
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::AppFramework::Canvas': typeof Inbox2AppFrameworkCanvas;
    'inbox2/app-framework/canvas': typeof Inbox2AppFrameworkCanvas;
  }
}
