/* import __COLOCATED_TEMPLATE__ from './results-panel.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { useResource } from 'ember-resources';
import NavigableSelection from '../../common/navigable-selection-resource';
import type CommandKService from 'embercom/services/command-k';
import { type SearchByTypeResponse } from 'embercom/services/quick-search';
import { take } from 'underscore';
import type UseMacroAction from 'embercom/objects/inbox/command-k/use-macro';
import type Inbox2MacrosSearch from 'embercom/services/inbox2-macros-search';
import { modifier } from 'ember-modifier';
import type ThemeService from 'embercom/services/theme-service';

interface Args {
  actionItem: UseMacroAction;
  onSelect: (s: ChildAction, a?: object) => void;
  query: string;
  macroResults: SearchByTypeResponse;
  onInsert?: () => void;
  inTypeahead: boolean;
}

interface Signature {
  Args: Args;
}

export default class UseMacroResultsPanelComponent extends Component<Signature> {
  @service declare themeService: ThemeService;
  @service declare commandK: CommandKService;
  @service declare inbox2MacrosSearch: Inbox2MacrosSearch;

  selection = useResource(this, NavigableSelection, () => ({
    items: this.filteredItems,
    isCommandKVisible: this.commandK.isVisible,
  }));

  constructor(owner: unknown, args: any) {
    super(owner, args);
    if (this.args.onInsert) {
      this.args.onInsert();
    }
  }

  selectFirstResultOnChange = modifier(() => {
    if (this.selection.items.length) {
      this.selection.select(this.selection.items[0]);
    }
  });

  get initialMacrosData(): SearchByTypeResponse {
    return this.args.macroResults;
  }

  get frequentlyUsedMacros() {
    return take(this.initialMacrosData.results, 6);
  }

  get frequentlyUsedMacrosIds() {
    return this.frequentlyUsedMacros.map((item) => item.data.id);
  }

  get itemsForFrequentlyUsedMacros() {
    return this.args.actionItem.buildResults({
      total: this.frequentlyUsedMacros.length,
      results: this.frequentlyUsedMacros,
    });
  }

  get itemsExcludingFrequentlyUsed() {
    let data = this.initialMacrosData.results.filter(
      (item) => !this.frequentlyUsedMacrosIds.includes(item.data.id),
    );

    return this.args.actionItem.buildResults({
      total: data.length,
      results: data.sort((a, b) => a.data.name.localeCompare(b.data.name)),
    });
  }

  get filteredItems(): Array<ChildAction> {
    return this.args.actionItem.buildResults({
      total: this.initialMacrosData.total,
      results: this.initialMacrosData.results,
    });
  }

  @action selectItem(section: string, macro: ChildAction) {
    this.args.onSelect(macro, { macro_section: section });
  }

  get containerSelectorName(): string {
    return 'js__inbox-macros-pane';
  }

  get loadingIndicatorColor(): 'white' | 'blue' {
    return this.themeService.darkModeEnabled ? 'white' : 'blue';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::UseMacro::ResultsPanel': typeof UseMacroResultsPanelComponent;
    'inbox2/command-k/use-macro/results-panel': typeof UseMacroResultsPanelComponent;
  }
}
