/* import __COLOCATED_TEMPLATE__ from './conversation-details-sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import type InboxState from 'embercom/services/inbox-state';
import Conversation, { NewConversation } from 'embercom/objects/inbox/conversation';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import type User from 'embercom/objects/inbox/user';

// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import { type SupportedSidebarSection } from 'embercom/services/inbox-sidebar-service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type Session from 'embercom/services/session';
import type CopilotApi from 'embercom/services/copilot-api';

export interface ConversationDetailsSidebarArgs {
  conversation: Conversation | NewConversation;
  openSidebarSections?: Array<string | number>;
  onOpenSidebarSectionsChange?: Function;
  onAdminUpdate(assignee: AdminWithPermissions): void;
  onTeamUpdate(team: TeamSummary): void;
  onUserUpdated?: (user: User) => void;
  sidebarSections?: SupportedSidebarSection[];
  onCollapse: () => unknown;
  setTicketTypeToAddToConversation: (ticketTypeId: number) => void;
  isResizable?: boolean;
  canOpenInNewTab?: boolean;
  isFullConversationPanel?: boolean;
  toggleVisibility?: () => unknown;
}

interface Signature {
  Args: ConversationDetailsSidebarArgs;
}

export default class ConversationDetailsSidebar extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare session: Session;
  @service declare copilotApi: CopilotApi;

  @tracked lastConversationId?: number;

  @trackedReset('args.conversation.firstParticipant') userId? =
    this.args.conversation.firstParticipant?.id;

  readonly defaultInbox = { id: 'all', category: 'shared' };

  constructor(owner: unknown, args: ConversationDetailsSidebarArgs) {
    super(owner, args);

    if (this.args.conversation instanceof Conversation) {
      this.lastConversationId = this.args.conversation.id;
    }
  }

  get inbox() {
    return this.inboxState.activeInbox || this.defaultInbox;
  }

  get showPreviewPanel() {
    return this.inboxSidebarService.isPreviewingConversation;
  }

  get shouldShowEvenlySplitPanels() {
    return this.args.isFullConversationPanel && this.isPreviewPanelOpen;
  }

  get isPreviewPanelOpen() {
    return (
      this.inboxSidebarService.isViewingSideConversation ||
      this.inboxSidebarService.isViewingNewSideConversationPanel ||
      this.inboxState.isConversationListHidden ||
      this.inboxSidebarService.isPreviewingConversation ||
      this.inboxSidebarService.isViewingStartLinkedConversation ||
      this.inboxSidebarService.isViewingKnowledgeBase ||
      this.inboxSidebarService.isViewingCopilotSourcePreview ||
      this.inboxSidebarService.isPreviewingLinkedItem
    );
  }

  get shouldShowSidebarShadow() {
    return !this.args.isFullConversationPanel && this.isPreviewPanelOpen;
  }

  get copilotAnalyticsData() {
    if (!(this.args.conversation instanceof Conversation)) {
      return {};
    }

    let lastRenderablePart = this.args.conversation.lastRenderablePart;
    let lastTeammatePart =
      this.args.conversation.humanAdminComments?.sortBy('createdAt').lastObject;
    let lastEndUserPart = this.args.conversation.userComments?.sortBy('createdAt').lastObject;

    return {
      conversation_id: this.args.conversation.id,
      last_part_entity_type: lastRenderablePart?.entityType,
      last_part_entity_id: lastRenderablePart?.entityId,
      last_teammate_part_entity_id: lastTeammatePart?.entityId,
      last_teammate_part_entity_type: lastTeammatePart?.entityType,
      last_end_user_part_entity_id: lastEndUserPart?.entityId,
      last_end_user_part_entity_type: lastEndUserPart?.entityType,
    };
  }

  get shouldRenderOverflowYScroll(): boolean {
    if (this.inboxSidebarService.activeTab === 'copilot' && this.copilotApi.isCopilotEnabled) {
      return true;
    } else if (this.inboxSidebarService.activeTab === 'details') {
      return true;
    } else {
      return false;
    }
  }

  @action onConversationChange() {
    let { conversation } = this.args;

    if (conversation instanceof Conversation && conversation.id !== this.lastConversationId) {
      this.inboxSidebarService.isPreviewingConversation = false;
      this.inboxSidebarService.toggleIsViewingStartLinkedConversation({ visibility: false });
      this.inboxSidebarService.closeNewSideConversationPanel();
      this.inboxSidebarService.isViewingPhoneDetails = false;
      this.lastConversationId = conversation.id;
    }
  }

  @action onPreviewConversationChange(isPreviewing = false) {
    this.inboxSidebarService.isPreviewingConversation = isPreviewing;
  }

  @action onTeamUpdate(team: TeamSummary) {
    if (this.args.conversation instanceof NewConversation) {
      this.args.onTeamUpdate(team);
    } else {
      this.inboxState.assignConversationToTeam(this.args.conversation, team);
    }
  }

  @action onAdminUpdate(admin: AdminWithPermissions) {
    if (this.args.conversation instanceof NewConversation) {
      this.args.onAdminUpdate(admin);
    } else {
      this.inboxState.assignConversationToAdmin(this.args.conversation, admin);
    }
  }

  @action async onSelectTicketType(ticketTypeId: number) {
    if (this.args.conversation instanceof NewConversation) {
      return;
    }

    this.args.setTicketTypeToAddToConversation(ticketTypeId);
  }

  @action onExpandedSectionChange(sectionId: SupportedSidebarSection | number | undefined) {
    this.inboxSidebarService.onExpandedSectionChange(
      sectionId,
      this.args.conversation instanceof Conversation ? this.args.conversation?.id : undefined,
    );
  }

  @action openKnowledgePanelSheet() {
    this.inboxSidebarService.openKnowledgeBasePanel(this.args.conversation.id, this.userId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar': typeof ConversationDetailsSidebar;
    'inbox2/conversation-details-sidebar': typeof ConversationDetailsSidebar;
  }
}
