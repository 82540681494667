/* import __COLOCATED_TEMPLATE__ from './conversation-card.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import type InboxApi from 'embercom/services/inbox-api';
import type IntlService from 'embercom/services/intl';
import { InboxSortOption } from 'embercom/models/data/inbox/inbox-filters';
import { tracked } from '@glimmer/tracking';

interface Arguments {
  conversationSummary: ConversationSummary;
  selectConversation: (id: number) => void;
  selectedSortOption: InboxSortOption;
  cardIndex: number;
}

interface Signature {
  Args: Arguments;
}

export default class ConversationCard extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare intl: IntlService;

  @tracked isHovering = false;

  get heading(): string {
    let { conversationSummary } = this.args;

    if (conversationSummary.hasMultipleParticipants) {
      return this.intl.t('inbox.conversation-list.conversation-participants-summary', {
        firstParticipant: conversationSummary.user.displayAs,
        remainingCount: conversationSummary.participantSummaries.length - 1,
      });
    }

    return this.args.conversationSummary.user.displayAs;
  }

  get ticketHeading(): string {
    let { conversationSummary } = this.args;
    return typeof conversationSummary.ticketTitle === 'string'
      ? conversationSummary.ticketTitle
      : this.heading;
  }

  get timestamp(): Date | undefined {
    return this.args.selectedSortOption === InboxSortOption.WaitingLongest
      ? this.args.conversationSummary.waitingSince
      : this.args.conversationSummary.lastUpdated;
  }

  get dividerStyles() {
    let activeClass = 'active';
    let transparentClass = 'bg-transparent';
    let isElementActive = this.isHovering;

    if (this.args.cardIndex === 0) {
      return isElementActive ? `${activeClass} ${transparentClass}` : transparentClass;
    }

    if (isElementActive) {
      return `${activeClass} ${transparentClass}`;
    }

    return 'bg-neutral-container ';
  }

  @action selectConversation() {
    this.args.selectConversation(this.args.conversationSummary.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::Drilldown::ConversationCard': typeof ConversationCard;
    'inbox2/dashboard/drilldown/conversation-card': typeof ConversationCard;
  }
}
