/* import __COLOCATED_TEMPLATE__ from './part-group.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import { PartGroupCategory } from 'embercom/objects/inbox/conversation-stream/part-group';
import { type TableEntryHighlights } from 'embercom/objects/inbox/conversation-table-entry';

interface Signature {
  Args: {
    partGroup: PartGroup;
    conversation: Conversation;
    tinyAvatar?: boolean;
    highlightedConversationPartId?: string;
    highlights?: TableEntryHighlights;
    isTicketStream?: boolean;
    isPreviewPanel?: boolean;
    isReadOnly: boolean;
    isStandalone?: boolean;
  };
}

export default class Inbox2ConversationStreamPartGroupComponent extends Component<Signature> {
  categories = PartGroupCategory;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroup': typeof Inbox2ConversationStreamPartGroupComponent;
  }
}
