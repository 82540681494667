/* import __COLOCATED_TEMPLATE__ from './call-modal-actions-list.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';

interface Signature {
  Element: HTMLElement;
  Args: {
    isConferenceCallActive: boolean;
    isCollapsed: boolean;
    isMicrophoneOn: boolean;
    renderOnHold: boolean;
    canToggleHold: boolean;
    isOnHold: boolean;
    renderTransfer: boolean;
    renderWarmTransfer: boolean;
    canTransfer: boolean;
    renderAddParticipant: boolean;
    canAddParticipant: boolean;
    disableEndCallButton?: boolean;
    isWarmTransferCallActive?: boolean;
    endCall: any;
    completeWarmTransfer: any;
    onlyShowHangup?: boolean;
    leaveCall: () => void;
    toggleMicrophone: () => void;
    toggleOnHold: () => void;
    viewConversation: () => void;
    onTransferClick: () => void;
    onWarmTransferClick: () => void;
    onAddParticipantClick: () => void;
    openKeypad: () => void;
  };
}

export default class CallModalActionsList extends Component<Signature> {
  get showMoreButton() {
    return (
      this.args.renderAddParticipant || this.args.renderTransfer || this.args.renderWarmTransfer
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Calling::CallModalActionsList': typeof CallModalActionsList;
    'inbox2/calling/call-modal-actions-list': typeof CallModalActionsList;
  }
}
