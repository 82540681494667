/* import __COLOCATED_TEMPLATE__ from './table-row.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import {
  ConversationStateAction,
  type SortState,
} from 'embercom/components/inbox2/conversations-table';
import { inject as service } from '@ember/service';
import type InboxState from 'embercom/services/inbox-state';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { type DurationObject } from 'embercom/objects/inbox/duration';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import type CommandKService from 'embercom/services/command-k';
import type ConversationsTableActions from 'embercom/objects/inbox/conversations-table-actions';
import type ConversationsTableColumn from 'embercom/objects/inbox/conversations-table-column';
import type Session from 'embercom/services/session';
// @ts-ignore
import { cached } from 'tracked-toolbox';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import type ConversationOptions from 'embercom/services/inbox2-conversation-options-service';
// @ts-ignore
import intermoji from '@intercom/intermoji';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { type UpdateTicketStateCommandKContext } from 'embercom/objects/inbox/command-k/update-ticket-state';
import type IntlService from 'embercom/services/intl';
import { buildUpdateTicketStateCommandKContext } from 'embercom/lib/inbox2/ticket-custom-states';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import { SlaStatus } from 'embercom/objects/inbox/conversation-sla-state';
import type CompanySummary from 'embercom/objects/inbox/company-summary';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type ConversationResolveAndCloseService from 'embercom/services/conversation-resolve-and-close-service';

export const TABLE_ROW_TRACKING_SECTION = 'table_row';

interface Args {
  columns: ConversationsTableColumn[];
  conversationEntry: ConversationTableEntry;
  index: number;
  isActive: boolean;
  sortState: SortState;
  conversationsTableActions: ConversationsTableActions;
  isTableInFocus: boolean;
  useAnimations: boolean;
  scrollToActive: boolean;
  onClick: () => void;
  closePreviewPanel: () => void;
  onPreviewGithubIssues?: () => void;
  onConversationSelected: (
    options: {
      conversation: ConversationTableEntry;
    },
    event?: PointerEvent,
  ) => void;
  companyFilter?: CompanySummary;
}

interface Signature {
  Args: Args;
}

enum State {
  Default,
  Focused,
  Selected,
  BulkSelected,
  Actioned,
}

const BG_STYLES = new Map<State, string>([
  [State.Default, 'o__default'],
  [State.Focused, 'o__rounded bg-base-module'],
  [State.Selected, 'o__rounded bg-base-module shadow-lvl-0 active-nav-item'],
  [State.BulkSelected, 'o__rounded bg-neutral-container'],
  [State.Actioned, 'o__rounded bg-neutral-container'],
]);

export default class TableRow extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare commandK: CommandKService;
  @service declare session: Session;
  @service declare inbox2ConversationOptionsService: ConversationOptions;
  @service declare intl: IntlService;
  @service declare ticketStateService: TicketStateService;
  @service declare conversationResolveAndCloseService: ConversationResolveAndCloseService;

  @tracked hasActiveCell = false;
  @tracked showSlaConfirmationModal = false;

  get hasActivityContent() {
    let lastRenderableSummaryPart: RenderablePart | undefined =
      this.args.conversationEntry.lastRenderableSummaryPart;

    let hasRenderableData: boolean = lastRenderableSummaryPart?.renderableData !== undefined;

    let hasRenderableType: boolean =
      lastRenderableSummaryPart?.renderableData?.renderableType !== undefined;

    let hasValidRenderableType: boolean =
      lastRenderableSummaryPart !== undefined &&
      [RenderableType.TicketStateUpdatedByAdmin, RenderableType.TicketStateUpdated].includes(
        lastRenderableSummaryPart.renderableData.renderableType,
      );

    return hasRenderableData || (hasRenderableType && hasValidRenderableType);
  }

  get workspaceID() {
    return this.session.workspace.id;
  }

  get conversationId() {
    return this.args.conversationEntry.id;
  }

  get isSelected() {
    return this.args.isActive;
  }

  get isBulkSelected() {
    return this.inboxState.selectedConversations.ids.includes(this.conversationId);
  }

  get isFocused() {
    return this.args.isTableInFocus && this.isSelected;
  }

  get isRestricted(): boolean {
    return this.args.conversationEntry.redacted;
  }

  get state(): State {
    if (this.isFocused) {
      return State.Focused;
    } else if (this.isSelected) {
      return State.Selected;
    } else if (this.isBulkSelected) {
      return State.BulkSelected;
    } else if (this.hasActiveCell) {
      return State.Actioned;
    } else {
      return State.Default;
    }
  }

  get backgroundStyle(): string {
    return BG_STYLES.get(this.state) || '';
  }

  get userLocation() {
    if (this.args.conversationEntry.user?.geoipData) {
      let { cityName, countryName } = this.args.conversationEntry.user.geoipData;
      return [cityName, countryName].filter((n) => n).join(', ');
    } else {
      return;
    }
  }

  get hasSla() {
    return (
      this.args.conversationEntry.nextBreachTime !== undefined ||
      this.args.conversationEntry.conversationSlaStates?.some(
        (state) => state.status === SlaStatus.Paused,
      )
    );
  }

  get canRemoveSla() {
    return (
      this.args.conversationEntry.nextBreachTime &&
      !this.isRestricted &&
      !this.session.showLightInbox
    );
  }

  get emojiUri(): string | undefined {
    if (!this.args.conversationEntry.ticketType?.emoji) {
      return undefined;
    }

    return intermoji.twemojiSVGUri(this.args.conversationEntry.ticketType.emoji);
  }

  get currentTicketCustomState(): TicketCustomState {
    return this.ticketStateService.getTicketCustomStateById(
      this.args.conversationEntry.ticketCustomStateId,
    );
  }

  get updateTicketStateContext(): UpdateTicketStateCommandKContext {
    let accessibleStates = this.ticketStateService.customStates;

    if (this.args.conversationEntry.ticketType) {
      accessibleStates = this.ticketStateService.getLiveCustomStatesForTicketType(
        this.args.conversationEntry.ticketType,
      );
    }

    return buildUpdateTicketStateCommandKContext({
      customStates: accessibleStates,
      selectedCustomStateId: this.currentTicketCustomState.id,
      allowClosedState: false,
      intl: this.intl,
      conversation: {
        id: this.args.conversationEntry.id,
        visibleToUser: this.args.conversationEntry.visibleToUser,
      },
    });
  }

  get canBeResolved() {
    return this.args.conversationEntry.isTicket && !this.args.conversationEntry.isClosed;
  }

  @cached
  get columnAttributes() {
    let mapping = Object.assign(
      {},
      ...(this.args.conversationEntry.attributes ?? []).map((x: ConversationAttributeSummary) => ({
        [x.descriptor.id]: x,
      })),
    );
    return this.args.columns.map((column) =>
      column.isAttribute && column.descriptor ? mapping[column.descriptor.id] : undefined,
    );
  }

  get conversationsTableActions() {
    return this.args.conversationsTableActions;
  }

  @action assign(assignee: AdminWithPermissions | TeamSummary) {
    this.conversationsTableActions.assign(
      this.args.conversationEntry,
      assignee,
      TABLE_ROW_TRACKING_SECTION,
    );
    this.hasActiveCell = false;
  }

  @action snooze(duration: DurationObject) {
    this.conversationsTableActions.snooze(
      this.args.conversationEntry,
      duration,
      TABLE_ROW_TRACKING_SECTION,
    );
    this.hasActiveCell = false;
  }

  @action open() {
    this.conversationsTableActions.open(this.args.conversationEntry, TABLE_ROW_TRACKING_SECTION);
    this.hasActiveCell = false;
  }

  @action close() {
    this.conversationResolveAndCloseService.startResolveAndCloseProcess(
      this.args.conversationEntry,
      () => {
        this.hasActiveCell = false;
      },
    );
  }

  @action changeState(state: ConversationStateAction) {
    switch (state) {
      case ConversationStateAction.Open:
        this.open();
        return;
      case ConversationStateAction.Close:
        this.close();
        return;
    }
  }

  @action updateTicketState(ticketStateId: TicketCustomState['id']) {
    let newState = this.ticketStateService.getTicketCustomStateById(ticketStateId);
    this.conversationsTableActions.updateTicketState(
      this.args.conversationEntry,
      newState,
      TABLE_ROW_TRACKING_SECTION,
    );
    this.hasActiveCell = false;
  }

  @action showCommandKAction(
    action: 'changeState' | 'assignAdmin' | 'assignTeam' | 'updateTicketState',
  ) {
    this.hasActiveCell = true;

    let { actionID, context, onSelect } = {
      changeState: {
        actionID: 'change-state',
        context: { currentState: this.args.conversationEntry.state },
        onSelect: (value: 'open' | 'close' | DurationObject) => {
          switch (value) {
            case 'open':
              return this.open();
            case 'close':
              return this.close();
            default:
              // Snooze is the only remaining possibility
              return this.snooze(value);
          }
        },
      },
      assignAdmin: {
        actionID: 'assign-to',
        context: { excludeTeams: true },
        onSelect: (admin: AdminWithPermissions) => {
          this.assign(admin);
        },
      },
      assignTeam: {
        actionID: 'assign-to',
        context: { excludeAdmins: true },
        onSelect: (team: TeamSummary) => {
          this.assign(team);
        },
      },
      updateTicketState: {
        actionID: 'update-ticket-state',
        context: this.updateTicketStateContext,
        onSelect: (ticketStateId: TicketCustomState['id']) => {
          this.updateTicketState(ticketStateId);
        },
      },
    }[action];

    this.commandK.registerAndShow({
      actionID,
      context,
      onCancel: () => (this.hasActiveCell = false),
      onSelect,
    });
  }

  @action async removeSlaAndClose(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    let nextBreachTime = this.args.conversationEntry.nextBreachTime;

    this.showSlaConfirmationModal = false;
    this.args.conversationEntry.nextBreachTime = undefined;
    try {
      await this.inbox2ConversationOptionsService.removeSla(this.args.conversationEntry);
    } catch {
      this.args.conversationEntry.nextBreachTime = nextBreachTime;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableRow': typeof TableRow;
    'inbox2/conversations-table/table-row': typeof TableRow;
  }
}
