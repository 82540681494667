/* import __COLOCATED_TEMPLATE__ from './company.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type CompanySummary from 'embercom/objects/inbox/company-summary';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import type CompanyApi from 'embercom/services/company-api';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';

export interface Args {
  conversationEntry: ConversationTableEntry;
  companyFilter?: CompanySummary;
}

interface Signature {
  Args: Args;
  Element: HTMLParagraphElement;
}

export default class Company extends Component<Signature> {
  @service declare companyApi: CompanyApi;

  get companyId() {
    return this.args.conversationEntry.companyId;
  }

  get companyName() {
    if (this.args.companyFilter) {
      return this.args.companyFilter.name;
    }

    return this.args.conversationEntry.ticketCategory
      ? this.ticketCompanyName
      : this.args.conversationEntry.companyName;
  }

  get ticketCompanyName() {
    if (!this.companyId) {
      return undefined;
    }

    let companyFromUserSummary = this.args.conversationEntry.user?.companies?.find((company) => {
      return company.id === this.companyId;
    });

    if (companyFromUserSummary) {
      return companyFromUserSummary.name;
    }

    let companyFromParticipantSummary: CompanySummary | undefined;
    this.args.conversationEntry.participantSummaries.find((participantSummary) => {
      companyFromParticipantSummary = participantSummary.companies?.find((company) => {
        return company.id === this.companyId;
      });
      return companyFromParticipantSummary !== undefined;
    });

    if (companyFromParticipantSummary) {
      return companyFromParticipantSummary.name;
    }

    if (this.fetchCompany.value) {
      return this.fetchCompany.value.name;
    }

    return undefined;
  }

  get isUnsafeAssociation() {
    if (this.args.conversationEntry.ticketCategory) {
      return false;
    }

    let allCompanyIds = this.args.conversationEntry.participantSummaries
      .flatMap((participantSummary) => participantSummary.companyIds)
      .uniq();
    return allCompanyIds.length > 1;
  }

  get numberOfRelatedCompanies() {
    return this.args.conversationEntry.parent?.userSummary?.companyIds
      ? this.args.conversationEntry.parent.userSummary.companyIds.length
      : 0;
  }

  @use fetchCompany = AsyncData<CompanySummary | undefined | null>(async () => {
    if (!this.companyId) {
      return undefined;
    }

    let company = await this.companyApi.fetchCompany(this.companyId);
    return company;
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCells::Company': typeof Company;
    'inbox2/conversations-table/table-cells/company': typeof Company;
  }
}
