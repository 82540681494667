/* import __COLOCATED_TEMPLATE__ from './operator-workflow-event.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type OperatorWorkflowEvent from 'embercom/objects/inbox/renderable/operator-workflow-event';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import LinkWithText from 'embercom/helpers/link-with-text';
import { humanReadableObjectNamesKeys } from 'embercom/models/data/matching-system/matching-constants';
import simpleCapitalize from 'embercom/lib/simple-capitalize';

interface OperatorWorkflowEventPart extends RenderablePart {
  renderableData: OperatorWorkflowEvent;
}

interface Args {
  part: OperatorWorkflowEventPart;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamEventPartsOperatorWorkflowEventComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;

  // @ts-ignore helper doesn't seem to be typed correctly
  linkWithTextHelper = LinkWithText.create(this);

  get adminTitle() {
    let admin = this.args.part.renderableData.eventData.admin;

    if (admin) {
      return this.linkWithTextHelper.compute(['apps.app.admins.admin', admin.id, admin.name]);
    } else {
      return '';
    }
  }

  get skipAssignmentActionData() {
    let skipAssignmentAction = this.args.part.renderableData.eventData.skipAssignmentAction;

    if (skipAssignmentAction) {
      let outboundType = this.intl.t(
        humanReadableObjectNamesKeys[skipAssignmentAction.outboundType],
        {
          count: 1,
        },
      );
      let link = this.linkWithTextHelper.compute([
        `apps.app.outbound.find`,
        skipAssignmentAction.outboundId,
        skipAssignmentAction.outboundName,
      ]);
      return {
        outboundType: simpleCapitalize(outboundType),
        outboundName: link,
      };
    } else {
      return {
        outboundType: '',
        outboundName: '',
      };
    }
  }

  get translatedEvent() {
    switch (this.args.part.renderableData.eventType) {
      case 'wait_started':
        return this.waitStartedEvent;
      case 'start_workflow_requested':
        return this.intl.t('inbox.operator-workflow-event.events.start_workflow_requested', {
          admin: this.adminTitle,
          htmlSafe: true,
        });
      case 'skip_assignment_action':
        return this.intl.t('inbox.operator-workflow-event.events.skip_assignment_action', {
          ...this.skipAssignmentActionData,
          htmlSafe: true,
        });
      case 'workflow_finished':
        return this.intl.t(
          `inbox.operator-workflow-event.events.workflow_finished.${
            this.args.part.renderableData.eventData.workflowFinished!.reason
          }`,
          {
            htmlSafe: true,
          },
        );
      case 'tag_user':
        return this.intl.t('inbox.operator-workflow-event.events.tag_user', {
          tag: this.tagName,
          htmlSafe: true,
        });
      case 'remove_tag_from_user':
        return this.intl.t('inbox.operator-workflow-event.events.remove_tag_from_user', {
          tag: this.tagName,
          htmlSafe: true,
        });
      case 'add_tag_to_conversation':
        return this.intl.t('inbox.operator-workflow-event.events.add_tag_to_conversation', {
          tag: this.tagName,
          htmlSafe: true,
        });
      case 'remove_tag_from_conversation':
        return this.intl.t('inbox.operator-workflow-event.events.remove_tag_from_conversation', {
          tag: this.tagName,
          htmlSafe: true,
        });
      case 'user_inactivity_conversation_autoclosed':
        return this.intl.t(
          'inbox.operator-workflow-event.events.user_inactivity_conversation_autoclosed',
          {
            minutes:
              this.args.part.renderableData.eventData.userInactivityConversationAutoClosed!
                .endUserInactivityTimer / 60,
            htmlSafe: true,
          },
        );
      case 'language_override_updated':
        if (!this.args.part.renderableData.eventData.languageOverrideUpdated!.previousValue) {
          return this.intl.t('inbox.operator-workflow-event.events.language_override_updated.set', {
            language: this.intl.languageNameFromCode(
              this.args.part.renderableData.eventData.languageOverrideUpdated!.newValue,
            ),
            htmlSafe: true,
          });
        }
        return this.intl.t(
          'inbox.operator-workflow-event.events.language_override_updated.changed',
          {
            previousValue: this.intl.languageNameFromCode(
              this.args.part.renderableData.eventData.languageOverrideUpdated!.previousValue,
            ),
            newValue: this.intl.languageNameFromCode(
              this.args.part.renderableData.eventData.languageOverrideUpdated!.newValue,
            ),
            htmlSafe: true,
          },
        );
      case 'user_data_attribute_updated':
        return this.userDataAttributeUpdatedEvent;
      case 'ai_agent_ignoring_user_message':
        return this.intl.t(
          `inbox.operator-workflow-event.events.ai_agent_ignoring_user_message.${
            this.args.part.renderableData.eventData.aiAgentIgnoringUserMessage!.reason
          }`,
          {
            htmlSafe: true,
          },
        );
      case 'ai_agent_workflow_started':
        return this.intl.t('inbox.operator-workflow-event.events.ai_agent_workflow_started', {
          title: this.args.part.renderableData.eventData.aiAgentWorkflowStarted!.title,
          htmlSafe: true,
        });
      default:
        return this.intl.t(
          `inbox.operator-workflow-event.events.${this.args.part.renderableData.eventType}`,
          {
            default: 'inbox.operator-workflow-event.events.unknown',
            ...this.args.part.renderableData.eventData,
          },
        );
    }
  }

  get waitStartedEvent() {
    let durationType =
      this.args.part.renderableData.eventData.waitStarted?.durationType || 'custom';

    if (durationType === 'custom') {
      let customDuration = this.args.part.renderableData.eventData.waitStarted?.customDuration || 0;
      let days = Math.floor(customDuration / 86400);
      let hours = Math.floor((customDuration % 86400) / 3600);
      let minutes = Math.floor((customDuration % 3600) / 60);
      let seconds = Math.floor(customDuration % 60);

      let formatKey = '';
      if (days > 0) {
        formatKey += 'd';
      }
      if (hours > 0) {
        formatKey += 'h';
      }
      if (minutes > 0) {
        formatKey += 'm';
      }
      if (seconds > 0) {
        formatKey += 's';
      }

      return this.intl.t(`inbox.operator-workflow-event.events.wait_started.${formatKey}`, {
        days,
        hours,
        minutes,
        seconds,
      });
    } else {
      return this.intl.t(`inbox.operator-workflow-event.events.wait_started.${durationType}`);
    }
  }

  get tagName() {
    let actionData = this.args.part.renderableData.eventData.actionData;

    if (!actionData) {
      return '';
    }

    if (actionData.tagId) {
      return this.session.store.peekRecord('tag', actionData.tagId)?.name || '';
    }

    return '';
  }

  get userDataAttributeUpdatedEvent() {
    let attribute = this.args.part.renderableData.eventData.actionData!.attributeIdentifier;
    let value = this.args.part.renderableData.eventData.actionData!.value?.toString();
    if (!attribute || !value) {
      return '';
    }

    if (attribute.startsWith('custom_data.')) {
      attribute = attribute.replace('custom_data.', '');
    }

    return this.intl.t('inbox.operator-workflow-event.events.user_data_attribute_updated', {
      attribute,
      value,
      htmlSafe: true,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::OperatorWorkflowEvent': typeof Inbox2ConversationStreamEventPartsOperatorWorkflowEventComponent;
    'inbox2/conversation-stream/event-parts/operator-workflow-event': typeof Inbox2ConversationStreamEventPartsOperatorWorkflowEventComponent;
  }
}
