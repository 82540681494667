/* import __COLOCATED_TEMPLATE__ from './linked-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { ConversationState } from 'embercom/objects/inbox/conversation';
import type LinkedTicketSummary from 'embercom/objects/inbox/linked-ticket-summary';
import type InboxState from 'embercom/services/inbox-state';

interface Args {
  Args: {
    linkedConversation: LinkedTicketSummary;
    previewOnly?: boolean;
  };
  Element: HTMLElement;
}

export default class LinkedConversation extends Component<Args> {
  @service declare inboxState: InboxState;

  get participant() {
    return this.args.linkedConversation.firstParticipant;
  }

  get activeConversationId() {
    return this.inboxState?.activeConversation?.id;
  }

  get hideViewingthisConversationTooltip() {
    return this.activeConversationId !== this.args.linkedConversation.id;
  }

  get stateStyling(): string {
    return {
      [ConversationState.Closed]: 'bg-neutral-container text-muted  ',
      [ConversationState.Snoozed]: 'bg-notice-container text-default  ',
      [ConversationState.Open]: 'bg-neutral-container-emphasis text-link  ',
    }[this.args.linkedConversation.state];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Links::LinkedConversation': typeof LinkedConversation;
    'inbox2/conversation-details-sidebar/sidebar-sections/links/linked-conversation': typeof LinkedConversation;
  }
}
