/* import __COLOCATED_TEMPLATE__ from './bot-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type CustomBot from 'embercom/objects/inbox/renderable/custom-bot';
import type BotComment from 'embercom/objects/inbox/renderable/bot-comment';
import type ChannelAndReplyTimeExpectation from 'embercom/objects/inbox/renderable/channel-and-reply-time-expectation';
import type BotNote from 'embercom/objects/inbox/renderable/bot-note';
import type FinAnswer from 'embercom/objects/inbox/renderable/fin-answer';

interface BotContentRenderablePart extends RenderablePart {
  renderableData: CustomBot | BotComment | ChannelAndReplyTimeExpectation | BotNote | FinAnswer;
}

interface BotContentPartGroup extends PartGroup {
  parts: Array<BotContentRenderablePart>;
}

interface Args {
  isTicketStream: boolean;
  partGroup: BotContentPartGroup;
  isReadOnly: boolean;
  isStandalone?: boolean;
}

interface Signature {
  Args: Args;
}

export default class ConversationStreamTeammateContentComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;

  get renderableData() {
    return this.args.partGroup.parts[0].renderableData;
  }

  get avatarObject() {
    if (this.customBotSummary) {
      return {
        id: this.customBotSummary.id,
        name: this.intl.t('inbox.custom-bot', { name: this.customBotSummary.title }),
        imageURL: this.renderableData.entity.imageURL,
      };
    } else {
      return this.renderableData.creatingEntity.object;
    }
  }

  get avatarRoute() {
    if (this.customBotSummary) {
      return 'apps.app.operator.custom-bots.custom-bot.show';
    } else {
      return 'apps.app.admins.admin';
    }
  }

  get customBotSummary() {
    return 'customBotSummary' in this.renderableData && this.renderableData.customBotSummary;
  }

  appendPublicSources = (part: BotContentRenderablePart) => {
    return (
      'sources' in part.renderableData &&
      part.renderableData.sources &&
      part.renderableData.sources.length > 0
    );
  };
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::BotContent': typeof ConversationStreamTeammateContentComponent;
    'inbox2/conversation-stream/part-groups/bot-content': typeof ConversationStreamTeammateContentComponent;
  }
}
