/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import { inject as service } from '@ember/service';
import { type Checklist, ChecklistStatus } from 'embercom/objects/inbox/checklists';

interface Args {
  checklist: Checklist;
  onChecklistClick: (checklist: Checklist) => void;
}

interface Signature {
  Args: Args;
}

export default class Card extends Component<Signature> {
  @service declare intl: IntlService;

  @action handleClick() {
    this.args.onChecklistClick(this.args.checklist);
  }

  get completedTasks() {
    return this.args.checklist.completedTasks.length;
  }

  get checklistStatusText() {
    if (this.args.checklist.status === ChecklistStatus.Complete) {
      return this.intl.t('inbox.conversation-details-sidebar.checklists.complete-label');
    }

    return this.completedTasks > 0
      ? this.intl.t('inbox.conversation-details-sidebar.checklists.in-progress-label')
      : this.intl.t('inbox.conversation-details-sidebar.checklists.not-started-label');
  }

  get checklistStatusStyling() {
    if (this.args.checklist.status === ChecklistStatus.Complete) {
      return 'bg-neutral-container text-muted  ';
    }

    return this.completedTasks > 0
      ? 'bg-neutral-container-emphasis text-link  '
      : 'bg-notice-container text-default  ';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Checklists::Card': typeof Card;
    'inbox2/conversation-details-sidebar/sidebar-sections/checklists/card': typeof Card;
  }
}
