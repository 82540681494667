/* import __COLOCATED_TEMPLATE__ from './ticket-attribute-row.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import { TicketDescriptor, type TicketType } from 'embercom/objects/inbox/ticket';

interface Args {
  attribute: TicketAttributeSummary;
  descriptor: TicketDescriptor;
  onUpdateTicketAttribute?: (
    attribute: TicketAttributeSummary,
    descriptor?: TicketDescriptor,
  ) => void;
  isReadOnly?: boolean;
  isCreatingTicket?: boolean;
  showError?: boolean;
  ticketType?: TicketType;
  visibleToUser?: boolean;
  listOptions?: Record<string, string[]>;
}

interface Signature {
  Args: Args;
}

export default class TicketAttributeRow extends Component<Signature> {
  get showErrorOnFocus() {
    if (
      !this.args.isCreatingTicket &&
      this.args.descriptor instanceof TicketDescriptor &&
      this.args.descriptor.requiredToCreate &&
      this.args.attribute instanceof TicketAttributeSummary &&
      this.args.attribute.isEmpty
    ) {
      return true;
    } else {
      return false;
    }
  }

  get isDynamicHeight() {
    if (this.args.isReadOnly) {
      return this.args.descriptor.isFilesType;
    }
    return this.args.descriptor.isDynamicHeight;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPage::TicketPanel::TicketAttributeRow': typeof TicketAttributeRow;
    'inbox2/conversation-page/ticket-panel/ticket-attribute-row': typeof TicketAttributeRow;
  }
}
