/* import __COLOCATED_TEMPLATE__ from './cell.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { isBlank } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type Session from 'embercom/services/session';
import {
  type Breakdown,
  type BreakdownSettings,
} from 'embercom/services/inbox2-monitoring-breakdown-service';
import type Inbox2MonitoringBreakdownService from 'embercom/services/inbox2-monitoring-breakdown-service';
import type Inbox2OverviewService from 'embercom/services/inbox2-overview-service';

interface Args {
  value: number;
  clarificationValue?: string;
  qualifier?: string;
  useColorRange?: boolean;
  lowRange?: number;
  midRange?: number;
  maxRange?: number;
  timeframe?: number;
  settings?: BreakdownSettings;
  metricType?: string;
  inboxId?: string;
  openDrilldownDrawer: (options: object) => void;
}

interface Signature {
  Args: Args;
}

export default class Cell extends Component<Signature> {
  @service declare session: Session;
  @service declare inbox2OverviewService: Inbox2OverviewService;
  @service declare inbox2MonitoringBreakdownService: Inbox2MonitoringBreakdownService;

  @tracked value = this.args.value;
  @tracked clarificationValue = this.args.clarificationValue;
  @tracked breakdown: Breakdown | undefined = undefined;

  popover: any = null;
  isHovering = false;

  get qualifier() {
    return this.args.qualifier || '';
  }

  get text() {
    if (!isBlank(this.value)) {
      return !isBlank(this.clarificationValue)
        ? `${this.value}${this.qualifier} (${this.clarificationValue})`
        : `${this.value}${this.qualifier}`;
    }
    return '-';
  }

  get maxRange() {
    return this.args.maxRange || 100;
  }

  get lowRange() {
    return 0.5 * this.maxRange;
  }

  get midRange() {
    return 0.85 * this.maxRange;
  }

  get textColor() {
    if (!isBlank(this.value) && this.args.useColorRange) {
      if (this.value < this.lowRange) {
        return 'text-error ';
      }
      if (this.value < this.midRange) {
        return 'text-default ';
      }
      return 'success-fill ';
    }
    return 'text-default ';
  }

  get isLink() {
    return !!this.args.metricType;
  }

  @action
  openDrilldownDrawer() {
    if (this.args.metricType) {
      this.args.openDrilldownDrawer({
        inboxId: this.args.inboxId,
        metricType: this.args.metricType,
        value: this.text,
        updateValue: this.updateValue,
      });
    }
  }

  format(value: string) {
    switch (this.args.metricType) {
      case 'avg_wait_time':
        return this.inbox2OverviewService.getAvgWaitTime(value);
      case 'csat':
        return this.inbox2OverviewService.getCsat(value);
      default:
        return value;
    }
  }

  @action updateValue(rawValue: string) {
    if (this.args.metricType === 'sla') {
      this.clarificationValue = rawValue;
    } else {
      this.value = this.format(rawValue);
    }
  }

  @task({ restartable: true })
  *fetchPopoverBreakdown() {
    this.breakdown = yield this.inbox2MonitoringBreakdownService.fetchBreakdown({
      metric: { inboxId: this.args.inboxId, metricType: this.args.metricType || '' },
      timeframe: this.args.timeframe,
      settings: this.args.settings,
    });
    if (this.isHovering) {
      this.popover?.show();
      this.updateValue(this.breakdown?.buckets[0].value || '');
    }
  }

  get metricHasBreakdown() {
    return [
      'active_teammates',
      'waiting_for_first_admin_reply',
      'idle',
      'avg_wait_time',
      'csat',
      'sla',
    ].includes(this.args.metricType || '');
  }

  get metricLabel() {
    return {
      active_teammates: 'Active Teammates',
      waiting_for_first_admin_reply: 'Waiting for First Reply',
      idle: 'Idle',
      avg_wait_time: 'Avg. Wait Time',
      csat: 'CSAT',
      sla: 'SLA',
    }[this.args.metricType || ''];
  }

  @action setPopover(popover: any) {
    this.popover = popover;
  }

  @action hidePopover(event: any) {
    if (event.relatedTarget.classList.contains('inbox2-overview__cell__popover')) {
      this.popover?.hide();
    } else {
      // mouseleave event should fire here
    }
  }

  @action
  async onHover() {
    this.isHovering = true;
    if (this.metricHasBreakdown) {
      // We want to trigger popover data fetching only if cursor hovers longer than 200ms
      setTimeout(
        () => (this.isHovering ? taskFor(this.fetchPopoverBreakdown).perform() : null),
        200,
      );
    } else {
      setTimeout(() => (this.isHovering ? this.popover.show() : null), 200);
    }
  }

  @action
  onHidePopover() {
    this.isHovering = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::InboxOverview::Cell': typeof Cell;
    'inbox2/dashboard/inbox-overview/cell': typeof Cell;
  }
}
