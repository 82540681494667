/* import __COLOCATED_TEMPLATE__ from './call-modal-participant-list.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import type CallParticipantSummary from 'embercom/objects/inbox/callings/call-participant-summary';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type IntercomCallService from 'embercom/services/intercom-call-service';
import type Session from 'embercom/services/session';
import type TwilioService from 'embercom/services/twilio-service';

interface Args {
  user?: UserSummary;
  participants: CallParticipantSummary[];
  isConferenceCall: boolean;
  isCollapsed: boolean;
  addingParticipantLabel?: string;
  warmTransferTeammate?: AdminSummary;
  warmTransferUser?: UserSummary;
  isWarmTransferCallActive?: boolean;
}

export default class CallModalParticipantList extends Component<Args> {
  @service declare intercomCallService: IntercomCallService;
  @service declare session: Session;
  @service declare twilioService: TwilioService;
  @service declare intl: IntlService;

  get listItemStyle() {
    if (this.args.isCollapsed) {
      return 'text-muted gap-1';
    }

    return 'text-white bg-neutral-container-emphasis gap-3 px-4 h-17';
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  @action
  getName(participant: CallParticipantSummary) {
    return this.isCurrentAdmin(participant)
      ? this.intl.t('calling.modal.you')
      : participant?.displayLabel || this.intl.t('calling.modal.unknown-number');
  }

  @action
  isCurrentAdmin(arg: CallParticipantSummary) {
    return arg?.id === this.session.teammate?.id;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Calling::CallModalParticipantList': typeof CallModalParticipantList;
    'inbox2/calling/call-modal-participant-list': typeof CallModalParticipantList;
  }
}
