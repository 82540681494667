/* import __COLOCATED_TEMPLATE__ from './typing-animated-avatar.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import type Conversation from 'embercom/objects/inbox/conversation';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { TicketCategory } from 'embercom/objects/inbox/ticket';

interface Args {
  conversation: ConversationSummary | Conversation | ConversationTableEntry;
}

interface Signature {
  Args: Args;
}

export default class TypingAnimatedAvatar extends Component<Signature> {
  @service declare session: Session;

  get ticketIcon() {
    switch (this.args.conversation.ticketCategory) {
      case TicketCategory.Request:
        return 'ticket';
      case TicketCategory.Task:
        return 'back-office';
      case TicketCategory.Tracker:
        return 'tracker';
      default:
        return 'ticket';
    }
  }

  get isLastReplyByTeammate() {
    let lastPart = this.args.conversation?.lastRenderableSummaryPart;
    if (!lastPart) {
      return false;
    }

    if (lastPart.renderableData.renderableType === RenderableType.AdminNote) {
      return false;
    }

    return lastPart.renderableData.creatingEntity.type === EntityType.Admin;
  }

  get isRestricted() {
    return this.args.conversation.redacted;
  }

  get adminAvatarTextColor() {
    return 'var(--text-default)';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::TypingAnimatedAvatar': typeof TypingAnimatedAvatar;
    'inbox2/common/typing-animated-avatar': typeof TypingAnimatedAvatar;
  }
}
