/* import __COLOCATED_TEMPLATE__ from './conversation-details.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Conversation from 'embercom/objects/inbox/conversation';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { task } from 'ember-concurrency-decorators';
import type InboxState from 'embercom/services/inbox-state';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import { type SidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';
import type ConditionalAttributesService from 'embercom/services/conditional-attributes-service';

interface Args {
  conversation: Conversation;
  conversationAttributeDescriptors: ConversationAttributeDescriptor[];
  isOpen: boolean;
  name: string;
  onOpenSectionChange: () => unknown;
  autofocusTitle?: boolean;
  onConversationOrChecklistSelected?: () => void;
  previewOnly?: boolean;
  isPreviewingConversation?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: SidebarSectionComponentArgs<Args>;
}

export default class ConversationDetails extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare conditionalAttributesService: ConditionalAttributesService;

  @tracked visibleToUser = true;
  @trackedReset('args.conversation.id') titleInput = this.args.conversation.title || '';
  @trackedReset('args.conversation.id') formSubmitted = false;

  get conditionalAttributesEvaluator() {
    return this.conditionalAttributesService.initialize({
      conversation: this.args.conversation,
      descriptors: this.args.conversationAttributeDescriptors,
    });
  }

  conversationAttributesDescriptors() {
    if (!this.session.workspace.canUseConditionalAttributes) {
      return this.args.conversationAttributeDescriptors;
    }

    return this.conditionalAttributesEvaluator.updateVisibility();
  }

  get visibleConversationAttributeDescriptors() {
    return this.conversationAttributesDescriptors()?.filter(
      (descriptor: ConversationAttributeDescriptor) =>
        !descriptor.archived &&
        !descriptor.hideFromInboxSidebar &&
        descriptor.isVisibleToTeamId(this.args.conversation.teamAssignee?.id),
    );
  }

  get conversationAttributesById() {
    return this.args.conversation.attributes?.reduce(
      (byIds, attribute) => {
        byIds[attribute.descriptor.id] = attribute;
        return byIds;
      },
      {} as Record<string, ConversationAttributeSummary>,
    );
  }

  get hasCreatedByValue() {
    return this.conversationAttributesById?.['created_by_teammate_id']?.value;
  }

  get showConversationSubject(): boolean {
    return !this.args.conversation.isTicket;
  }

  get header(): string {
    return this.intl.t(
      'inbox.conversation-details-sidebar.section-headings.conversation-attributes',
    );
  }

  @action async onUpdateAttribute(attribute: ConversationAttributeSummary) {
    await this.conditionalAttributesEvaluator.updateAttribute(attribute);
  }

  @action toggleVisibleToUser() {
    this.visibleToUser = !this.visibleToUser;
  }

  @task({ keepLatest: true })
  *updateConversationTitle() {
    let previousTitle = this.args.conversation.title;
    let newTitle = this.titleInput?.trim();

    if (newTitle === previousTitle) {
      return;
    }
    yield this.inboxState.updateConversationTitle(this.args.conversation, newTitle, previousTitle);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::ConversationDetails': typeof ConversationDetails;
    'inbox2/conversation-details-sidebar/conversation-details': typeof ConversationDetails;
  }
}
