/* import __COLOCATED_TEMPLATE__ from './editable-ticket-attributes.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import { DEFAULT_TICKET_TYPE_ATTRIBUTES } from 'embercom/lib/inbox/constants';
import type Router from '@ember/routing/router-service';
import type TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import { helper } from '@ember/component/helper';
import { type TicketDescriptor, type TicketType } from 'embercom/objects/inbox/ticket';
import { TicketCategory } from 'embercom/objects/inbox/ticket';

interface Args {
  selectedTicketType: TicketType;
  ticketAttributes: TicketAttributeSummary[];
  labelRowSize: string;
  visibleToUser?: boolean;
  showPlaceholders?: boolean;
  createLinkedTicket?: () => void;
  isLoading?: boolean;
  listOptions?: Record<string, string[]>;
  onUpdateTicketAttribute?: (
    attribute: TicketAttributeSummary,
    descriptor?: TicketDescriptor,
  ) => void;
}

interface Signature {
  Args: Args;
}

export default class EditableTicketAttributes extends Component<Signature> {
  @service declare session: Session;
  @service declare router: Router;

  get ticketAttributesToDisplay() {
    return this.args.ticketAttributes.filter(
      (attribute) =>
        attribute.descriptor.name !== DEFAULT_TICKET_TYPE_ATTRIBUTES.TITLE &&
        attribute.descriptor.name !== DEFAULT_TICKET_TYPE_ATTRIBUTES.DESCRIPTION,
    );
  }

  get titleTicketAttribute() {
    return this.ticketAttributeSummary(DEFAULT_TICKET_TYPE_ATTRIBUTES.TITLE);
  }

  get descriptionTicketAttribute() {
    return this.ticketAttributeSummary(DEFAULT_TICKET_TYPE_ATTRIBUTES.DESCRIPTION);
  }

  get ticketTitleVisible() {
    return this.ticketAttributeVisible(DEFAULT_TICKET_TYPE_ATTRIBUTES.TITLE);
  }

  get ticketDescriptionVisible() {
    return this.ticketAttributeVisible(DEFAULT_TICKET_TYPE_ATTRIBUTES.DESCRIPTION);
  }

  get showPlaceholders() {
    return this.args.showPlaceholders ?? true;
  }

  ticketAttributeSummary(name: string) {
    return this.args.ticketAttributes.filter((attribute) => attribute.descriptor.name === name)[0];
  }

  ticketAttributeVisible(attributeName: string) {
    let category = this.args.selectedTicketType?.category;
    let attribute = this.ticketAttributeSummary(attributeName);
    let description = attribute.descriptor;

    if (category) {
      switch (category) {
        case TicketCategory.Tracker:
          return false;
        case TicketCategory.Request:
          return description.visibleToUsers;
        case TicketCategory.Task:
          if (this.args.selectedTicketType?.internal || this.args.visibleToUser === false) {
            return false;
          } else {
            return description.visibleToUsers;
          }
      }
    } else {
      return description.visibleToUsers;
    }
    return false;
  }

  showError = helper(function ([attribute, performCount]: [TicketAttributeSummary, number]) {
    return performCount > 0 && attribute.showErrorOnCreate;
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::EditableTicketAttributes': typeof EditableTicketAttributes;
    'inbox2/editable-ticket-attributes': typeof EditableTicketAttributes;
  }
}
