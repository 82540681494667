/* import __COLOCATED_TEMPLATE__ from './create-linked-ticket-panel.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { TicketTypeResource } from 'embercom/components/inbox2/ticket-type-resource';
import { useResource } from 'ember-resources';
import { task } from 'ember-concurrency-decorators';
import { NewConversation } from 'embercom/objects/inbox/conversation';
import type Snackbar from 'embercom/services/snackbar';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import type Conversation from 'embercom/objects/inbox/conversation';
import type InboxState from 'embercom/services/inbox-state';
import type TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import type Router from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import { action } from '@ember/object';

export interface CreateTicketPanelArgs {
  originalConversation: Conversation;
  ticketTypeToLinkId?: number;
  hidePanel: () => void;
}

interface Signature {
  Args: CreateTicketPanelArgs;
}

export default class CreateLinkedTicketPanel extends Component<Signature> {
  @service declare snackbar: Snackbar;
  @service declare intl: IntlService;
  @service declare session: Session;
  @service inboxState!: InboxState;
  @service declare router: Router;
  @service declare intercomEventService: any;

  @tracked visibleToUser = false;

  selectedUserCompanyId: string | null = null;
  isDefaultSelectedUserCompanyId = true;

  readonly newConversation: NewConversation = new NewConversation();

  readonly ticketTypeResource = useResource(this, TicketTypeResource, () => ({
    ticketTypeId: this.args.ticketTypeToLinkId,
    conversation: undefined,
  }));

  @task({ drop: true })
  *createLinkedTicket() {
    if (this.ticketTypeResource.hasInvalidTicketAttributes) {
      this.snackbar.notifyError(
        this.intl.t('inbox.new-conversation.errors.invalid-ticket-attributes'),
      );
      return;
    }

    let conversation = this.newConversation;

    let data = {
      app_id: this.session.workspace.id,
      admin_assignee_id: conversation.adminAssignee?.id,
      team_assignee_id: conversation.teamAssignee?.id,
      ticket_type_id: this.ticketTypeResource.selectedTicketType!.id,
      visible_to_user: this.visibleToUser,
      user_id: this.args.originalConversation.firstParticipant?.id,
      original_conversation_id: this.args.originalConversation.id,
      company_id: this.selectedUserCompanyId,
    } as Record<string, unknown>;

    data.attributes = this.ticketTypeResource.ticketAttributes?.map(
      (attributeSummary: TicketAttributeSummary) => {
        return {
          descriptor_id: attributeSummary.descriptor.id,
          value: attributeSummary.serializedValue,
        };
      },
    );

    try {
      let ticket: Conversation = yield this.inboxState.createLinkedTicket(
        this.args.originalConversation,
        data,
      );

      this.trackTicketCreationEvent();

      this.snackbar.notify(
        this.intl.t('inbox.link-ticket-panel.ticket-created', {
          ticketTypeName: this.ticketTypeResource.selectedTicketType?.name,
          ticketCreated: this.intl.t('inbox.link-ticket-panel.linked-label'),
        }),
        {
          buttonLabel: this.intl.t('inbox.link-ticket-panel.view-ticket'),
          onButtonClick: (notification) => {
            this.snackbar.clearNotification(notification);
            this.router.transitionTo(
              'inbox.workspace.inbox.inbox.conversation.conversation',
              ticket.id,
            );
          },
        },
      );
      this.args.hidePanel();
    } catch (e) {
      let errorText =
        e?.jqXHR?.responseJSON?.errors ||
        this.intl.t('inbox.link-ticket-panel.error-creating-ticket');
      this.snackbar.notifyError(errorText);
    }
  }

  trackTicketCreationEvent() {
    let eventData: Record<string, string | boolean> = {
      object: 'ticket',
      action: 'created',
      section: 'create_linked_ticket_panel',
      ticket_category: this.ticketTypeResource.selectedTicketType!.category,
    };

    if (!this.ticketTypeResource.selectedTicketType?.isTrackerTicketType) {
      eventData = {
        ...eventData,
        company_associated: !!this.selectedUserCompanyId,
        default_company_selection_changed: !this.isDefaultSelectedUserCompanyId,
      };
    }

    this.intercomEventService.trackAnalyticsEvent(eventData);
  }

  get isCreatingTicket() {
    return taskFor(this.createLinkedTicket).isRunning;
  }

  get panelTitle() {
    if (this.ticketTypeResource.selectedTicketType?.isTrackerTicketType) {
      return this.intl.t('inbox.create-ticket-panel.create-tracker-ticket-button-label');
    }

    return this.intl.t('inbox.create-ticket-panel.create-backoffice-ticket-button-label');
  }

  @action onVisibilityChange(visibilityChange: boolean) {
    this.visibleToUser = visibilityChange;
  }

  @action
  handleCompanySelected(companyId: string | null, isDefaultSelection: boolean) {
    this.selectedUserCompanyId = companyId;
    this.isDefaultSelectedUserCompanyId = isDefaultSelection;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CreateLinkedTicketPanel': typeof CreateLinkedTicketPanel;
    'inbox2/create-linked-ticket-panel': typeof CreateLinkedTicketPanel;
  }
}
