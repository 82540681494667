/* import __COLOCATED_TEMPLATE__ from './avatar.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import PALETTE from '@intercom/pulse/lib/palette';

interface Args {
  color?: string;
  icon?: InterfaceIconName;
  iconColour?: keyof typeof PALETTE;
  initials?: string;
  imageURL?: string;
  userId?: string;
  isActive?: boolean;
  isTyping?: boolean;
  typingDotColor?: string;
  showActiveStatus?: boolean;
  tiny?: boolean;
  small?: boolean;
  large?: boolean;
  huge?: boolean;
  isAway?: boolean;
  outline?: boolean;
  shape?: 'squircle' | undefined;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
  Blocks: {
    default: [{ backgroundColor: string | undefined; textColor: string }];
  };
}

export default class CommonAvatarComponent extends Component<Signature> {
  @service declare session: Session;

  get showActiveStatus() {
    return this.args.showActiveStatus ?? 'true';
  }

  get style(): string {
    if (this.args.imageURL) {
      return `background-image: url('${this.args.imageURL.replace(/'/g, "\\'")}');`;
    } else {
      let potentiallyPrefixedBackgroundColor = this.backgroundColor;
      if (
        !potentiallyPrefixedBackgroundColor?.startsWith('var') &&
        !potentiallyPrefixedBackgroundColor?.startsWith('#')
      ) {
        potentiallyPrefixedBackgroundColor = `#${potentiallyPrefixedBackgroundColor}`;
      }
      return `background-color: ${potentiallyPrefixedBackgroundColor}; color: ${this.textColor}`;
    }
  }

  get backgroundColor() {
    return this.args.color;
  }

  get textColor() {
    return PALETTE['text-default'];
  }

  get dotColor() {
    return `background-color: ${this.args.typingDotColor || this.textColor}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::Avatar': typeof CommonAvatarComponent;
  }
}
