/* import __COLOCATED_TEMPLATE__ from './linked-ticket-v2.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type LinkedTicketSummary from 'embercom/objects/inbox/linked-ticket-summary';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import type InboxApi from 'embercom/services/inbox-api';
import { tracked } from '@glimmer/tracking';
import type Conversation from 'embercom/objects/inbox/conversation';
import type InboxState from 'embercom/services/inbox-state';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Args: {
    ticket: LinkedTicketSummary;
    onUnlinkTicket?: (ticketToUnlink: number) => void;
    conversation: Conversation;
    previewOnly?: boolean;
  };
  Element: HTMLElement;
}

export default class LinkedTicketV2 extends Component<Signature> {
  @service declare intl: IntlService;
  @service inboxApi!: InboxApi;
  @service declare session: Session;
  @service declare inboxState: InboxState;

  @tracked showUnlinkingConfirmation = false;

  get activeConversationId() {
    return this.inboxState?.activeConversation?.id;
  }

  get categoryName() {
    return this.intl.t(`inbox.ticket-details-header.categories.names.${this.args.ticket.category}`);
  }

  get categoryIcon(): InterfaceIconName {
    if (this.args.ticket.category === TicketCategory.Request) {
      return 'ticket';
    } else if (this.args.ticket.category === TicketCategory.Tracker) {
      return 'tracker';
    }
    return 'back-office';
  }

  @action
  onUnlinkTicket() {
    this.showUnlinkingConfirmation = false;
    this.args.onUnlinkTicket && this.args.onUnlinkTicket(this.args.ticket.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Links::LinkedTicketV2': typeof LinkedTicketV2;
    'inbox2/conversation-details-sidebar/sidebar-sections/links/linked-ticket-v2': typeof LinkedTicketV2;
  }
}
