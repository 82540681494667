/* import __COLOCATED_TEMPLATE__ from './link.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  text: string;
  link: string;
  icon?: InterfaceIconName;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class Inbox2CommonLinkComponent extends Component<Signature> {
  get linkClasses() {
    return `
          text-default 
          hover:text-link 
          active:text-link 
          focus:text-link focus:ring-2 focus:ring-color-blue/30  focus:ring-offset-1 
        `;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::Link': typeof Inbox2CommonLinkComponent;
    'inbox2/common/link': typeof Inbox2CommonLinkComponent;
  }
}
