/* import __COLOCATED_TEMPLATE__ from './ticket-state-updated-by-admin.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type TicketStateUpdatedByAdmin from 'embercom/objects/inbox/renderable/ticket-state-updated-by-admin';
import LinkWithText from 'embercom/helpers/link-with-text';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type InboxApi from 'embercom/services/inbox-api';
import { type TicketType, TicketCategory } from 'embercom/objects/inbox/ticket';
import { trackedFunction } from 'ember-resources/util/function';
import type Router from '@ember/routing/router-service';
import type Conversation from 'embercom/objects/inbox/conversation';
//@ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { TicketSystemState } from 'embercom/objects/inbox/conversation';

interface TicketStateUpdatedByAdminPart extends RenderablePart {
  renderableData: TicketStateUpdatedByAdmin;
}

interface Args {
  part: TicketStateUpdatedByAdminPart;
  conversation: Conversation;
  isTicketStream: boolean;
  hideTicketLink?: boolean;
  hideLinks?: boolean;
}

interface Signature {
  Args: Args;
}

type TranslationData = {
  key: string;
  data: {
    value: string;
    who?: string;
    ticketLink?: string;
  };
};

export default class TicketStateUpdatedByAdminPartComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare inboxApi: InboxApi;

  // @ts-ignore helper doesn't seem to be typed correctly
  linkWithTextHelper = LinkWithText.create(this);

  get ticketState() {
    return this.args.part.renderableData.ticketState;
  }

  get ticketStateAdminlabel() {
    return this.args.part.renderableData.adminLabel;
  }

  get ticketTypes(): TicketType[] {
    return this.ticketTypesLoader.value ?? [];
  }

  private ticketTypesLoader = trackedFunction(this, async () => {
    return await this.inboxApi.listTicketTypes();
  });

  ticketLink() {
    let fallbackText = this.intl.t(`inbox.ticket-state.new-event-part.fallback-ticket-type-name`);

    let id = this.renderableData?.id;
    let ticketId = this.renderableData?.ticketId;
    let ticketTypeId = this.renderableData?.ticketTypeId;

    if (ticketId === undefined || id === undefined) {
      return fallbackText;
    }

    let ticketType = undefined;
    let ticketTitle = this.renderableData?.ticketTitle;

    if (ticketTypeId !== undefined) {
      ticketType = this.ticketTypes.find((ticketType) => ticketType.id === ticketTypeId);
    }

    if (ticketType === undefined && ticketTitle === undefined) {
      return fallbackText;
    }

    let displayText = ticketTitle ?? ticketTitle ?? ticketType?.name;
    displayText = `${displayText} #${ticketId}`;

    let href = this.router.urlFor(this.router.currentRouteName, id);

    return sanitizeHtml(
      `<a class="hover:text-link  no-underline" target="_blank" href=${href}>${displayText}</a>`,
    );
  }

  get renderablePartTicketCategory(): TicketCategory | null | undefined {
    let ticketTypeId = this.renderableData?.ticketTypeId;

    if (ticketTypeId === undefined) {
      return null;
    }
    let ticketType = this.ticketTypes.find((ticketType) => ticketType.id === ticketTypeId);

    return ticketType?.category;
  }

  get renderableData() {
    return this.args.part.renderableData;
  }

  get teammate() {
    return this.renderableData.adminSummary;
  }

  get translatedTicketState() {
    return this.intl.t(`inbox.ticket-state.${this.ticketState}`);
  }

  get didIAuthorTheEvent() {
    return this.teammate.id === this.session.teammate.id;
  }

  get translationData(): TranslationData {
    let ticketState = this.ticketStateAdminlabel
      ? this.ticketStateAdminlabel
      : this.translatedTicketState;
    let data: TranslationData = {
      key: this.translationKey,
      data: {
        value: ticketState,
      },
    };

    if (!this.didIAuthorTheEvent) {
      if (this.args.hideLinks) {
        data.data.who = this.teammate.name;
      } else {
        data.data.who = this.linkWithTextHelper.compute([
          'apps.app.admins.admin',
          this.teammate.id,
          this.teammate.name,
        ]);
      }
    }

    if (this.isLinkedEntity) {
      data.data.ticketLink = this.ticketLink();
    }

    return data;
  }

  get shouldRenderNewTicketEventPart() {
    return this.args.part.isNewTicketEventPart;
  }

  get isLinkedEntity() {
    // As this event part is for ticket state updates
    // If the main conversation is not a ticket, the part must be a linked entity
    if (!this.args.conversation.isTicket) {
      return true;
    }

    // Else, if the ticket category of the renderable part exists, and is different
    // From the main conversation ticket category, then the part must be a linked entity
    return (
      this.renderablePartTicketCategory &&
      this.renderablePartTicketCategory !== this.args.conversation.ticketType?.category
    );
  }

  get isTicketStateSubmitted() {
    return this.ticketState === 'submitted';
  }

  get isTicketShared() {
    return this.renderableData.isShared;
  }

  // The first submitted state is a special case when we want to render the "shared" event part
  // If the previous state is the same as the current state, it's the first submitted state
  // If a part contains the custom state id(s), we need to compare those as well
  get isFirstSubmittedState() {
    // ticketState and previousTicketState refer to the ticket's system state (i.e. "submitted", "in_progress", etc.)
    // ticketCustomStateId and previousTicketCustomStateId are the custom state id(s)
    let { ticketCustomStateId, previousTicketCustomStateId, previousTicketState } =
      this.renderableData;

    let isFirstSubmitted =
      this.ticketState === TicketSystemState.Submitted && this.ticketState === previousTicketState;

    if (!ticketCustomStateId || !previousTicketCustomStateId) {
      return isFirstSubmitted;
    }

    return ticketCustomStateId === previousTicketCustomStateId;
  }

  get translationKey() {
    let key = 'changed-ticket-state';

    // Only render the "shared" event part if it's the first submitted event
    if (this.shouldRenderNewTicketEventPart && this.isFirstSubmittedState && this.isTicketShared) {
      key = 'submitted-and-shared-ticket';
    }

    key = this.didIAuthorTheEvent ? `you-${key}` : `admin-${key}`;

    return this.fullyQualifyTranslationKey(key);
  }

  // This event part can be rendered in the context of any ticket category or conversation
  //
  // For customer tickets and conversations we want to display the icon of the ticket that had its state updated
  //
  // For tracker and back office tickets we want to show the corresponding icon
  get ticketIcon() {
    let conversationTicketCategory = this.args.conversation.ticketType?.category;

    if (
      conversationTicketCategory === TicketCategory.Request ||
      conversationTicketCategory === undefined
    ) {
      let partTicketCategory = this.renderablePartTicketCategory;

      if (partTicketCategory === TicketCategory.Tracker) {
        return 'tracker';
      } else if (partTicketCategory === TicketCategory.Task) {
        return 'back-office';
      } else if (partTicketCategory === TicketCategory.Request) {
        return 'ticket';
      }
    } else if (conversationTicketCategory === TicketCategory.Tracker) {
      return 'tracker';
    } else if (conversationTicketCategory === TicketCategory.Task) {
      return 'back-office';
    }

    // We should never get here
    return undefined;
  }

  fullyQualifyTranslationKey(key: string) {
    if (this.shouldRenderNewTicketEventPart) {
      if (this.isLinkedEntity) {
        if (this.args.hideTicketLink) {
          return `inbox.ticket-state.new-event-part.linked-conversation.summary.${key}`;
        } else {
          return `inbox.ticket-state.new-event-part.linked-conversation.${key}`;
        }
      } else {
        return `inbox.ticket-state.new-event-part.ticket.${key}`;
      }
    }

    return `inbox.ticket-state.${key}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::TicketStateUpdatedByAdmin': typeof TicketStateUpdatedByAdminPartComponent;
    'inbox2/conversation-stream/event-parts/ticket-state-updated-by-admin': typeof TicketStateUpdatedByAdminPartComponent;
  }
}
