/* import __COLOCATED_TEMPLATE__ from './table-header.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type ConversationsTableColumn from 'embercom/objects/inbox/conversations-table-column';
import { type SortState, type SortDirection } from '../conversations-table';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';

interface Args {
  column: ConversationsTableColumn;
  sortState: SortState;
  sortColumn: (column: ConversationsTableColumn, direction: SortDirection) => void;
  hideColumn: (column: ConversationsTableColumn) => void;
  onColumnChanged: () => void;
  disabled: boolean;
}

interface Signature {
  Args: Args;
}

export default class TableHeader extends Component<Signature> {
  @tracked isHovered = false;

  private startingPosistionAndSize = { x: 0, width: 0 };

  removeTabindex = modifier(
    (element: HTMLElement) => {
      element.setAttribute('tabindex', '-1');
    },
    { eager: false },
  );

  get actionItems() {
    return [
      {
        items: [
          {
            id: 'sort-asc',
            include: this.column.isSortable,
            onSelectItem: () => this.args.sortColumn(this.column, 'asc'),
          },
          {
            id: 'sort-desc',
            include: this.column.isSortable,
            onSelectItem: () => this.args.sortColumn(this.column, 'desc'),
          },
          {
            id: 'hide',
            include: this.canReorder,
            onSelectItem: () => this.args.hideColumn(this.column),
          },
        ]
          .filterBy('include')
          .map((item) => ({
            ...item,
            component: 'inbox2/conversations-table/table-header-action-item',
          })),
      },
    ];
  }

  get column() {
    return this.args.column;
  }

  get type() {
    return this.column.type;
  }

  get style() {
    return `width: ${this.column.width}px; min-width: ${this.column.width}px;`;
  }

  get enabled() {
    return !this.args.disabled;
  }

  get background() {
    if (!this.canReorder) {
      return '';
    }

    // TODO(@22a): revisit and decide how this should behave
    if (this.column.isResizing || this.column.isReordering) {
      return '';
    }

    return this.enabled ? '' : '';
  }

  get resizingIndicatorColor() {
    if (this.column.isResizing) {
      return 'bg-accent-fill';
    }

    return this.enabled ? 'hover:bg-accent-fill' : '';
  }

  get cursor() {
    if (this.column.isReordering) {
      return 'cursor-grabbing';
    }

    return this.canReorder && this.enabled ? 'cursor-grab' : 'cursor-default';
  }

  get canReorder() {
    return !this.column.isFixed;
  }

  get isSortedColumn() {
    return this.args.sortState.sortField === this.column.sortField;
  }

  get shouldShowGrabIcon() {
    return this.isHovered && this.canReorder && this.enabled;
  }

  get shouldShowActions() {
    return this.isHovered && this.enabled && this.actionItems[0].items.length;
  }

  get shouldShowSortIcon() {
    return this.isSortedColumn;
  }

  get shouldShowTooltip() {
    let tooltip = this.args.column.tooltip;
    let doesTooltipHaveContent = tooltip?.title !== undefined || tooltip?.body !== undefined;

    return this.isHovered && this.enabled && doesTooltipHaveContent;
  }

  @action onResizeStart({ clientX }: MouseEvent) {
    this.startingPosistionAndSize = { x: clientX, width: this.column.width };
    this.column.isResizing = true;
    window.addEventListener('mousemove', this.onResizing, {
      passive: true,
    });
    window.addEventListener('mouseup', this.onResizeEnd);
  }

  @action onResizing({ clientX }: MouseEvent) {
    let newWidth = this.startingPosistionAndSize.width + clientX - this.startingPosistionAndSize.x;
    this.column.width = newWidth >= this.column.minWidth ? newWidth : this.column.minWidth;
  }

  @action onResizeEnd() {
    this.removeEventListeners();
    this.column.isResizing = false;
    this.args.onColumnChanged();
  }

  removeEventListeners() {
    window.removeEventListener('mouseup', this.onResizeEnd);
    window.removeEventListener('mousemove', this.onResizing);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableHeader': typeof TableHeader;
    'inbox2/conversations-table/table-header': typeof TableHeader;
  }
}
