/* import __COLOCATED_TEMPLATE__ from './attachment.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type Attachment } from '@intercom/interblocks.ts';
import Component from '@glimmer/component';
import { UploadScanState } from 'embercom/objects/inbox/renderable/upload';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface Signature {
  Args: {
    userEmailContent?: boolean;
    attachment?: Attachment & { scanState?: UploadScanState };
  };
}
export default class AttachmentComponent extends Component<Signature> {
  @service declare intl: IntlService;

  get id(): number {
    if (!this.args.attachment) {
      return 0;
    } else {
      return 'id' in this.args.attachment
        ? this.args.attachment.id
        : this.args.attachment.attachmentId;
    }
  }

  get scanState(): UploadScanState | undefined {
    return this.args.attachment?.scanState;
  }

  get isClean(): boolean {
    return this.scanState === UploadScanState.Clean;
  }

  get isInfected(): boolean {
    return this.scanState === UploadScanState.Infected;
  }

  get isExtensionMismatch(): boolean {
    return this.scanState === UploadScanState.ExtensionMismatch;
  }

  get stateIcon(): string {
    switch (this.scanState) {
      case UploadScanState.Inconclusive:
      case UploadScanState.ExtensionMismatch:
      case UploadScanState.Error:
        return 'inbox2/attachments/attachment-alert.svg';
      case UploadScanState.Infected:
        return 'inbox2/attachments/attachment-removed.svg';
      default:
        return '';
    }
  }

  get scanTextHeader(): string {
    return this.intl.t(`blocks.attachment.scan-states.header.${this.scanState}`);
  }

  get scanTextContent(): string {
    return this.intl.t(`blocks.attachment.scan-states.content.${this.scanState}`);
  }

  get iconAndTextColorClass(): string {
    if (this.isInfected) {
      return 'text-muted';
    } else {
      return 'text-default';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::Attachments::Attachment': typeof AttachmentComponent;
    'inbox2/conversation-stream/part-groups/attachments/attachment': typeof AttachmentComponent;
  }
}
