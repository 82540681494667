/* import __COLOCATED_TEMPLATE__ from './conversations-table.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { tracked } from '@glimmer/tracking';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxState from 'embercom/services/inbox-state';
import { ConversationsViewType, InboxEvents } from 'embercom/services/inbox-state';
import type Router from '@ember/routing/router-service';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { ComposerPaneType } from 'embercom/objects/inbox/composer-pane';
import { type DurationObject } from 'embercom/objects/inbox/duration';
import type Conversation from 'embercom/objects/inbox/conversation';
import { type CommandKMetadata } from 'embercom/services/command-k';
import type CommandKService from 'embercom/services/command-k';
import { type MacroAction } from 'embercom/objects/inbox/macro';
import { taskFor } from 'ember-concurrency-ts';
import { type RequiredAttributesPanelArgs } from './required-attributes-panel';
import ConversationsTableActions from 'embercom/objects/inbox/conversations-table-actions';
import type Snackbar from 'embercom/services/snackbar';
import {
  type ColumnDefinition,
  type ColumnGroup,
  type SortField,
} from 'embercom/objects/inbox/conversations-table-column';
import type ConversationsTableColumn from 'embercom/objects/inbox/conversations-table-column';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import type ConversationsTableSettings from 'embercom/services/conversations-table-settings';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import { type HotkeysMap } from 'embercom/services/inbox-hotkeys';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import type WorkflowConnectorAction from 'embercom/objects/inbox/workflow-connector-action';
import type WorkflowDetails from 'embercom/objects/inbox/workflow-details';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type ExperimentsApi from 'embercom/services/experiments-api';
import { type ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import { getOwner } from '@ember/application';
import type Nexus from 'embercom/services/nexus';
import type ConversationUpdates from 'embercom/services/conversation-updates';
import { type WithBoundArgs } from '@glint/template';
import { type BulkActionControlsArgs } from 'embercom/components/inbox2/common/bulk-action-controls';
import type BulkActionControls from 'embercom/components/inbox2/common/bulk-action-controls';
import { type InboxStateOption } from 'embercom/models/data/inbox/inbox-filters';
import type CompanySummary from 'embercom/objects/inbox/company-summary';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type Tracing from 'embercom/services/tracing';
import type ConversationResolveAndCloseService from 'embercom/services/conversation-resolve-and-close-service';
import type LbaMetricsService from 'embercom/services/lba-metrics-service';
import { LbaTriggerEvent } from 'embercom/services/lba-metrics-service';

export type SortDirection = 'asc' | 'desc';

export interface SortState {
  sortField: SortField;
  direction: SortDirection;
}

interface Args {
  inbox?: Inbox;
  isLinkedConversationsView?: boolean;
  linkedConversationIds?: number[];
  resource: ConversationsTableData;
  onPreviewConversation: (conversation: ConversationTableEntry, pane?: ComposerPaneType) => void;
  onClosePreview: () => void;
  descriptors: ConversationAttributeDescriptor[];
  columns: ConversationsTableColumn[];
  onSortColumn: (column: ConversationsTableColumn, direction: SortDirection) => void;
  selectedSortOption: SortState;
  selectedStateOption?: InboxStateOption;
  changeColumns: (columns: ColumnDefinition[]) => void;
  hiddenColumns: ColumnDefinition[] | ColumnGroup[];
  tableClassNames?: string;
  displayViewSwitcher?: boolean;
  estimateHeight?: number;
  companyFilter?: CompanySummary;
}

interface Signature {
  Args: Args;
  Blocks: {
    filters: [];
    emptyState: [];
    bulkActionControls: [
      {
        Component: WithBoundArgs<typeof BulkActionControls, keyof BulkActionControlsArgs>;
      },
    ];
  };
}

const COMMAND_K_TRACKING_SECTION = 'command_k';

export enum ConversationStateAction {
  Open = 'open',
  Close = 'close',
  Snooze = 'snooze',
}

export enum NavigationMode {
  Keyboard = 'keyboard',
  Mouse = 'mouse',
}

export interface ConversationsTableData {
  inboxConversationsCount: number;
  conversations: ConversationTableEntry[];
  selectedConversations: ConversationTableEntry[];
  canLoadMore: boolean;
  loadMore(metadata?: Record<string, any>): void;
  reload(): void;
  isLoading: boolean;
  isLoadingInForeground: boolean;
  hasError: boolean;
  hasConversations: boolean;
  nextCount: number;
  countAdditionalConversationsBeingFetched: number;
  active: ConversationTableEntry | undefined;
  getRelativeConversation(position: number): ConversationTableEntry | null;
  remove?: ({ id }: { id: number }) => void;
  searchUUID?: string;
  isInitialLoad: boolean;
  totalConversationsCount: number;
}

export type ShowRequiredAttributesPanelFct = (
  conversation: ConversationRecord,
  updateAttributesAndCloseConversation: (
    attributes: ConversationAttributeSummary[],
  ) => Promise<void>,
) => void;

export default class Inbox2ConversationsTable extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;
  @service declare session: Session;
  @service declare router: Router;
  @service declare commandK: CommandKService;
  @service declare snackbar: Snackbar;
  @service declare conversationsTableSettings: ConversationsTableSettings;
  @service declare inboxHotkeys: InboxHotkeys;
  @service declare tracing: Tracing;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare experimentsApi: ExperimentsApi;
  @service declare nexus: Nexus;
  @service declare conversationUpdates: ConversationUpdates;
  @service declare ticketStateService: TicketStateService;
  @service declare lbaMetricsService: LbaMetricsService;
  @service declare conversationResolveAndCloseService: ConversationResolveAndCloseService;

  @ref('conversations-table-table') table!: HTMLElement;

  @tracked shouldScrollToActiveConversation = true;
  @tracked isShowingBulkEditModal = false;
  @tracked navigationMode = NavigationMode.Mouse;
  @tracked requiredAttributesPanelArgs?: RequiredAttributesPanelArgs;
  @tracked selectedBulkAssignConversations: ConversationSummary['id'][] = [];
  @tracked bulkAssignSource?: string;
  @tracked isTableInFocus = false;

  @tracked private highlightedConversation?: ConversationTableEntry;

  readonly hotkeys: HotkeysMap;
  readonly handleNotEditorHotkey;
  readonly composerPaneType = ComposerPaneType;
  private numScrolls = 0;

  get isPreviewPanelOpen() {
    return (
      this.inboxSidebarService.isViewingSideConversation ||
      this.inboxSidebarService.isViewingNewSideConversationPanel ||
      this.inboxSidebarService.isDisplayingLinkTicketPanel ||
      this.inboxSidebarService.isPreviewingConversation ||
      this.inboxSidebarService.isViewingStartLinkedConversation ||
      this.inboxSidebarService.isViewingKnowledgeBase
    );
  }

  get shouldDisplayRightPanel() {
    return this.active || this.requiredAttributesPanelArgs;
  }

  get conversationsTableActions(): ConversationsTableActions {
    return new ConversationsTableActions({
      currentInbox: this.args.inbox,
      showRequiredAttributesPanel: this.showRequiredAttributesPanel,
      descriptors: this.args.descriptors,
      owner: getOwner(this),
    });
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.inboxState.on(InboxEvents.ConversationRead, this.handleConversationReadEvent);
    this.inboxState.on(InboxEvents.ShowRequiredAttributesPanel, this.showRequiredAttributesPanel);

    this.hotkeys = this.inboxHotkeys.hotkeysMap;
    this.handleNotEditorHotkey = this.inboxHotkeys.handleNotEditorHotkey;
  }

  willDestroy() {
    super.willDestroy();
    this.inboxState.off(InboxEvents.ConversationRead, this.handleConversationReadEvent);
  }

  get conversationsWithAccess() {
    return this.conversationEntryList.conversations.filter(
      (conversation) => !conversation.redacted,
    );
  }

  get conversationEntryList() {
    return this.args.resource;
  }

  get currentTicketCustomState(): TicketCustomState {
    return this.ticketStateService.getTicketCustomStateById(this.active?.ticketCustomStateId);
  }

  get firstSelectedTicketState(): TicketCustomState {
    return this.ticketStateService.getTicketCustomStateById(
      this.ticketsInBulkSelection[0]?.ticketCustomStateId,
    );
  }

  @action onDisplayActions() {
    this.commandK.show();
    this.trackBulkActionsCommandK();
  }

  private trackBulkActionsCommandK() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      section: 'conversation_list',
      object: 'command_k',
      conversation_ids: this.conversationEntryList.selectedConversations.map(({ id }) => id),
      layout_type: this.inboxState.activeConversationsView,
      count_conversations_selected: this.conversationEntryList.selectedConversations.length,
    });
  }

  @action showBulkEditModal() {
    this.inboxState.isShowingBulkEditModal = true;
  }

  get canReplyToSelectedConversations(): boolean {
    if (this.session.skipCanReplyToInboundCheck) {
      return true;
    }

    return this.inboxState.selectedConversations.conversationObjects.every((conversation) => {
      return !conversation.isInboundConversation;
    });
  }

  @action loadMore() {
    if (!this.conversationEntryList.canLoadMore) {
      return;
    }

    this.shouldScrollToActiveConversation = false;
    this.conversationEntryList.loadMore({
      number_of_scrolls: this.numScrolls,
    });
    this.numScrolls += 1;
  }

  @action showRequiredAttributesPanel(conversation: Conversation) {
    this.requiredAttributesPanelArgs = {
      conversation,
      descriptors: this.args.descriptors,
      updateAttributesAndCloseConversation: this.updateAttributesAndCloseConversation,
      hidePanel: this.hideRequiredAttributesPanel,
    };
  }

  @action async updateAttributesAndCloseConversation(
    conversation: ConversationRecord,
    attributes: ConversationAttributeSummary[],
  ) {
    this.lbaMetricsService.trackTeammateMaybeWaitingForNewConversationAt(
      LbaTriggerEvent.UPDATE_ATTR_AND_CLOSE,
    );
    return this.inboxState.updateAttributesAndClose(conversation, attributes);
  }

  @action hideRequiredAttributesPanel() {
    this.requiredAttributesPanelArgs = undefined;
  }

  @action previewGithubIssues(conversation: ConversationTableEntry) {
    this.onConversationClicked(conversation);
  }

  /**
   * Navigation actions
   */

  get active(): ConversationTableEntry | undefined {
    return this.conversationEntryList.active;
  }

  get isActiveReplyable(): boolean {
    return this.active?.isReplyable || false;
  }

  get isBulkSelectionReplyable(): boolean {
    return this.inboxState.selectedConversations.conversationObjects.every(
      (conversation) => conversation.isReplyable,
    );
  }

  scrollToActiveConversation() {
    this.shouldScrollToActiveConversation = true;
  }

  @action toggleSelection(
    options: {
      toggleAll?: boolean;
      conversation?: ConversationTableEntry;
    },
    event?: PointerEvent,
  ) {
    let { conversation, toggleAll = false } = options;

    if (toggleAll) {
      this.inboxState.selectedConversations.toggleAll(this.conversationsWithAccess);
      return;
    }

    if (conversation !== undefined) {
      this.inboxState.selectedConversations.toggle(
        conversation,
        this.conversationsWithAccess,
        event,
      );
      return;
    }
  }

  /**
   * Conversation actions
   */

  @action assign(assignee: AdminWithPermissions | TeamSummary) {
    this.active &&
      this.conversationsTableActions.assign(this.active, assignee, COMMAND_K_TRACKING_SECTION);
  }

  @action open() {
    this.active && this.conversationsTableActions.open(this.active, COMMAND_K_TRACKING_SECTION);
  }

  @action snooze(duration: DurationObject) {
    this.active &&
      this.conversationsTableActions.snooze(this.active, duration, COMMAND_K_TRACKING_SECTION);
  }

  @action close() {
    if (this.active) {
      this.conversationResolveAndCloseService.startResolveAndCloseProcess(this.active);
    }
  }

  @action changeState(state: ConversationStateAction) {
    switch (state) {
      case ConversationStateAction.Open:
        this.open();
        return;
      case ConversationStateAction.Close:
        this.close();
        return;
    }
  }

  @action togglePriority(_: unknown, __: unknown, metadata?: CommandKMetadata | undefined) {
    this.active &&
      this.conversationsTableActions.togglePriority(this.active, COMMAND_K_TRACKING_SECTION, {
        keyboardShortcutUsed: metadata?.keyboardShortcutUsed,
      });
  }

  @action triggerWorkflow(workflowDetails: WorkflowDetails) {
    this.active && taskFor(this.inboxState.triggerWorkflow).perform(this.active, workflowDetails);
  }

  @action triggerWorkflowConnectorAction(action: WorkflowConnectorAction) {
    this.active &&
      taskFor(this.inboxState.triggerWorkflowConnectorAction).perform(this.active, null, action);
  }

  /**
   * Bulk actions
   */

  @action toggleActiveConversation() {
    if (this.conversationEntryList.active) {
      this.toggleSelection({ conversation: this.conversationEntryList.active });
    }
  }

  @action handleArrowUp(event: KeyboardEvent) {
    if (event.shiftKey) {
      return;
    }

    this.onPreviousConversation();
  }

  @action handleArrowDown(event: KeyboardEvent) {
    if (event.shiftKey) {
      return;
    }

    this.onNextConversation();
  }

  @action handleShiftArrowUp() {
    this.onPreviousConversation();
    this.toggleActiveConversation();
  }

  @action handleShiftArrowDown() {
    this.onNextConversation();
    this.toggleActiveConversation();
  }

  get bulkActionsConversationsList() {
    if (this.args.isLinkedConversationsView && !this.inboxState.hasSelectedConversations) {
      let ids = this.args.linkedConversationIds ?? [];
      return this.conversationEntryList.conversations.filter((c: ConversationTableEntry) =>
        ids.includes(c.id),
      );
    } else {
      return this.conversationEntryList.selectedConversations;
    }
  }

  get isLinkedConversationViewWithTickets() {
    return this.args.isLinkedConversationsView && this.ticketsInBulkSelection.length > 0;
  }

  get ticketsInBulkSelection() {
    return this.bulkActionsConversationsList.filter(
      (item: ConversationTableEntry) => item.isTicket,
    );
  }

  get hasBulkActionableConversations() {
    return this.bulkActionsConversationsList.length > 0;
  }

  get allSelectedConversationsArePrioritized() {
    return this.bulkActionsConversationsList.every((c) => c.priority);
  }

  get someSelectedConversationsAreTickets() {
    return this.bulkActionsConversationsList.some((c) => c.isTicket);
  }

  get allSelectedConversationsAreClosed() {
    return this.bulkActionsConversationsList.every((c) => c.isClosed);
  }

  get canBulkReopen() {
    return this.allSelectedConversationsAreClosed;
  }

  @action async onUpdateTicketCustomState(state: TicketCustomState) {
    let updatableConversations = this.bulkActionsConversationsList.filter((conversation) => {
      return (
        conversation.isTicket && conversation.ticketType?.ticketCustomStateIds?.includes(state.id)
      );
    });
    let ids = updatableConversations.map((c) => c.id);
    if (ids.length === 0) {
      this.notificationsService.notifyError(
        this.intl.t('inbox.conversations-table.errors.update-ticket-state', {
          conversationsCount: this.bulkActionsConversationsList.length,
        }),
      );
      return;
    }

    let updates = Object.fromEntries(
      updatableConversations.map((conversation) => [
        conversation.id,
        this.conversationUpdates.addUpdate(conversation.id, 'ticket-state-change', {
          ticketState: state.systemState,
          visibleToUser: conversation.visibleToUser,
          conversationId: conversation.id,
          ticketTypeId: conversation.ticketType?.id,
          ticketCustomStateId: state.id,
        }),
      ]),
    );

    // deselect conversations that will be proceeded with the bulk action
    updatableConversations.forEach((conversation) =>
      this.inboxState.selectedConversations.toggle(conversation),
    );

    try {
      let result = await this.inboxApi.bulkChangeTicketState(ids, state.id);

      result.invalidConversationIds.forEach((conversationId) => {
        let conversation = this.conversationEntryList.conversations.find(
          (conversation) => conversation.id === conversationId,
        );
        this.conversationUpdates.rollbackUpdates(conversationId, [updates[conversationId]]);
        this.inboxState.selectedConversations.toggle(conversation!);
      });

      if (result.invalidConversationIds.length > 0) {
        this.notificationsService.notifyError(
          this.intl.t('inbox.conversations-table.errors.update-ticket-state', {
            conversationsCount: result.invalidConversationIds.length,
          }),
        );
        return;
      }

      if (this.bulkActionsConversationsList.length > 0) {
        this.notificationsService.notifyConfirmation(
          this.intl.t('inbox.conversations-table.success.update-ticket-state-not-all-updated', {
            conversationsUpdatedCount: ids.length,
            conversationsPendingCount: this.bulkActionsConversationsList.length,
          }),
        );
      } else {
        this.notificationsService.notifyConfirmation(
          this.intl.t('inbox.conversations-table.success.update-ticket-state', {
            conversationsCount: ids.length,
          }),
        );
      }
    } catch (error) {
      ids.forEach((conversationId) => {
        this.conversationUpdates.rollbackUpdates(conversationId, [updates[conversationId]]);
      });
      this.notificationsService.notifyError(
        this.intl.t('inbox.conversations-table.errors.update-ticket-state', {
          conversationsCount: ids.length,
        }),
      );
    }
  }

  @action async onUpdateTicketState(state: TicketCustomState['id']) {
    let customState = this.ticketStateService.getTicketCustomStateById(state);
    await this.onUpdateTicketCustomState(customState);
    return;
  }

  @action async togglePriorityForSelectedConversations() {
    let newPriority = !this.allSelectedConversationsArePrioritized;
    let targetConversations = this.bulkActionsConversationsList.filter(
      (c) => c.priority !== newPriority,
    );
    await this.conversationsTableActions.togglePriorityBulk(targetConversations, newPriority);
  }

  @task *editSelectedConversations(actions: MacroAction[]) {
    let ids = this.bulkActionsConversationsList.map((c) => c.id);
    this.inboxState.isShowingBulkEditModal = false;

    try {
      yield this.inboxState.applyBulkMacroActions(ids, actions);
      this.inboxState.selectedConversations.clear();
    } catch {
      this.notificationsService.notifyError(this.intl.t('inbox.bulk-edit.errors.edit'));
    }
  }

  @action closeSelectedConversations() {
    this.conversationResolveAndCloseService.startResolveAndCloseProcess(
      this.bulkActionsConversationsList,
    );
    this.trackBulkClose(this.bulkActionsConversationsList);
  }

  @task *reopenSelectedConversations() {
    let selectedConversations = this.bulkActionsConversationsList;
    yield this.conversationsTableActions.openBulk(selectedConversations);
  }

  private trackBulkClose(selectedConversations: ConversationTableEntry[]) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      section: 'conversation_list',
      object: 'conversations',
      conversation_ids: selectedConversations.map(({ id }) => id),
      layout_type: this.inboxState.activeConversationsView,
      count_conversations_selected: selectedConversations.length,
    });
  }

  @task *assignSelectedConversations(assignee: AdminSummary | TeamSummary) {
    let selectedConversations = this.bulkActionsConversationsList;
    this.selectedBulkAssignConversations = selectedConversations.map(
      (conversation) => conversation.id,
    );
    yield this.conversationsTableActions.assignBulk(selectedConversations, assignee);
    this.selectedBulkAssignConversations = [];
  }

  /**
   * Conversation changed event handlers
   */

  @action handleConversationReadEvent({ id }: Conversation) {
    this.conversationEntryList.conversations.findBy('id', id)?.markAsRead();
  }

  @action switchToSplitView(_: unknown, __: unknown, metadata?: CommandKMetadata | undefined) {
    this.inboxState.switchConversationsView(ConversationsViewType.List, {
      keyboardShortcutUsed: metadata?.keyboardShortcutUsed,
      section: metadata?.keyboardShortcutUsed ? undefined : 'command_k',
    });
  }

  @action closePreviewPanel() {
    this.args.onClosePreview();
    this.table.focus();
  }

  @action togglePreviewPanel() {
    this.active ? this.closePreviewPanel() : this.goToConversation(this.highlightedConversation);
  }

  @action onNextConversation() {
    let nextConversation = this.conversationEntryList.getRelativeConversation(1);
    if (!nextConversation) {
      return;
    }

    this.navigationMode = NavigationMode.Keyboard;
    this.goToConversation(nextConversation);
  }

  @action onPreviousConversation() {
    let previousConversation = this.conversationEntryList.getRelativeConversation(-1);
    if (!previousConversation) {
      return;
    }

    this.navigationMode = NavigationMode.Keyboard;
    this.goToConversation(previousConversation);
  }

  @action onConversationClicked(conversation: ConversationTableEntry) {
    if (this.inboxState.hasSelectedConversations) {
      return;
    }

    this.goToConversation(conversation);
  }

  @action private goToConversation(conversation?: ConversationTableEntry, pane?: ComposerPaneType) {
    if (!conversation) {
      return;
    }

    this.args.onPreviewConversation(conversation, pane);
    this.scrollToActiveConversation();
    this.highlightedConversation = conversation;

    if (!this.active) {
      this.table.focus();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable': typeof Inbox2ConversationsTable;
    'inbox2/conversations-table': typeof Inbox2ConversationsTable;
  }
}
