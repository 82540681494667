/* import __COLOCATED_TEMPLATE__ from './call-event.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const CallEvent = templateOnlyComponent<Signature>();
export default CallEvent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::CallEvent': typeof CallEvent;
    'inbox2/conversation-stream/event-parts/call-event': typeof CallEvent;
  }
}
