/* import __COLOCATED_TEMPLATE__ from './interblocks-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import Deleted from 'embercom/objects/inbox/renderable/deleted';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import {
  type Block,
  type Html,
  type Link,
  type MessengerCardBlock,
  type Paragraph,
} from '@intercom/interblocks.ts';
import { assert } from '@ember/debug';
import { captureException } from 'embercom/lib/sentry';
import { type TableEntryHighlights } from 'embercom/objects/inbox/conversation-table-entry';
import { type EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type IntlService from 'embercom/services/intl';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';

interface Args {
  part: RenderablePart & { renderableData: { blocks?: Block[] } };
  conversationId: number;
  highlights?: TableEntryHighlights;
  mentionStyling?: boolean;
  hideSourceLinks?: boolean;
  imageAsText?: boolean;
}

interface Signature {
  Args: Args;
}

function isMessengerCard(block?: Block): block is MessengerCardBlock {
  return block ? block.type === 'messengerCard' : false;
}

function isImageAttached(block?: Block) {
  return block ? block.type === 'image' : false;
}

function isHtml(block?: Block): block is Html {
  return block ? block.type === 'html' : false;
}

function isParagraph(block?: Block): block is Paragraph {
  return block ? block.type === 'paragraph' : false;
}

function isLink(block?: Block): block is Link {
  return block ? block.type === 'link' : false;
}

function shouldDiscardBlock(block?: Block): boolean {
  return block ? isParagraph(block) && block.text === '' && block.class !== 'no-margin' : false;
}

function shouldDiscardSourceBlock(block?: Block, hideSourceLinks = false): boolean {
  return block ? (block.type === 'sources' || isSourceLink(block)) && hideSourceLinks : false;
}

function isSourceLink(block?: Block): block is Link {
  if (!isLink(block)) {
    return false;
  }

  return block.linkType === 'resolution_bot.source';
}

function containsArticleLink(block?: Block): block is Paragraph {
  if (!isParagraph(block)) {
    return false;
  }

  return block.text.search('educate.article') > 0;
}

class RenderingGroup {
  blocks: Block[];

  constructor(blocks: Block[]) {
    this.blocks = blocks;
  }

  get hasBlocks() {
    return this.blocks.length > 0;
  }

  addBlock(block: Block) {
    this.blocks.push(block);
  }

  get isMessengerCardOnly(): boolean {
    return this.blocks.every(isMessengerCard);
  }

  get isHtmlOnly(): boolean {
    return this.blocks.every(isHtml);
  }

  get isSourceLinksOnly(): boolean {
    return this.blocks.every(isSourceLink);
  }
  get isWithImageOnly(): boolean {
    return this.blocks.any(isImageAttached);
  }

  get canvas() {
    let [block] = this.blocks;
    assert('Accessing canvas on a non-messengerCard block is not allowed', isMessengerCard(block));

    let { canvas } = block;
    if (!canvas) {
      return {};
    }

    return {
      content: null,
      ...canvas,
    };
  }
}
export default class Inbox2InterblocksComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;

  get blocks(): Block[] {
    if (this.args.part.renderableData instanceof Deleted) {
      return [];
    }
    if (this.args.part.renderableData.blocks === undefined) {
      captureException(new Error('Tried to render empty blocks'), {
        extra: {
          renderableType: this.args.part.renderableType,
          entityType: this.args.part.entityType,
        },
      });
    }
    return this.args.part.renderableData.blocks ?? [];
  }

  get renderingGroups(): RenderingGroup[] {
    let groups: RenderingGroup[] = [];
    let currentGroup = new RenderingGroup([]);

    this.blocks.forEach((block, index) => {
      if (shouldDiscardBlock(block)) {
        return;
      }

      if (shouldDiscardSourceBlock(block, this.args.hideSourceLinks)) {
        return;
      }

      let previousBlock = this.blocks[index - 1] as Block | undefined;

      if (containsArticleLink(block)) {
        block.class = 'intercom-interblocks-article-link';
      }

      if (
        isMessengerCard(block) ||
        isMessengerCard(previousBlock) ||
        isHtml(block) ||
        isHtml(previousBlock) ||
        isSourceLink(block) ||
        isSourceLink(previousBlock)
      ) {
        groups.push(currentGroup);
        currentGroup = new RenderingGroup([block]);
      } else {
        currentGroup.addBlock(block);
      }
    });

    groups.push(currentGroup);
    return groups.filter((group) => group.hasBlocks);
  }

  get isSideConversationReplyEventPart(): boolean {
    return this.args.part.renderableType === RenderableType.SideConversationReply;
  }

  get highlight(): string[] | undefined {
    let highlightedPart = this.args.highlights?.parts?.filter(
      (x: { entityId: number; entityType: EntityType; matches: string[] }) =>
        x.entityId === this.args.part.entityId && x.entityType === this.args.part.entityType,
    )[0];

    if (highlightedPart) {
      return highlightedPart.matches;
    }

    return [];
  }

  get trustedDomains() {
    return this.session?.workspace?.trustedDomains || [];
  }

  get trustedDomainsAndUrls() {
    return this.session?.workspace?.trustedDomainsAndUrls || [];
  }

  get isExternalLinkShieldEnabled() {
    return this.session.workspace?.externalLinkShieldEnabled;
  }

  get isMaliciousLinkBlockingEnabled() {
    return this.session.workspace?.maliciousLinkBlockingEnabled;
  }

  get maliciousLinks() {
    return this.session?.workspace?.maliciousLinks || [];
  }

  get blockedDomainsAndUrls() {
    return this.session?.workspace?.blockedDomainsAndUrls || [];
  }

  get isBlockedDomainsAndUrlsEnabled() {
    return this.session?.workspace?.blockedDomainsAndUrlsEnabled;
  }

  get appId() {
    if (this.session.isWorkspaceLoaded) {
      return this.session.workspace.id;
    }
    return '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::InterblocksComponent': typeof Inbox2InterblocksComponent;
    'inbox2/conversation-stream/interblocks-component': typeof Inbox2InterblocksComponent;
  }
}
