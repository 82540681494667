/* import __COLOCATED_TEMPLATE__ from './phone-available-teammates.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import type InboxApi from 'embercom/services/inbox-api';
import type IntlService from 'embercom/services/intl';
import { TEAMMATE_ACTIVITY_STATUS_CODE_MAP } from 'embercom/services/inbox2-teammate-activity-service';

interface Args {
  teammatesTableData: any;
}

interface Signature {
  Args: Args;
}

export default class PhoneAvailableTeammates extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare intl: IntlService;

  @tracked localData: object[] = [];

  tableIsLoading = false;
  @tracked tableIsLoadingMore = false;

  currentPage = 1;
  pageSize = 15;
  @tracked lastPageHit = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.fetchTeammates).perform();
  }

  get isEmpty() {
    return !this.args.teammatesTableData || this.args.teammatesTableData.length === 0;
  }

  @action
  onLoadMore() {
    // TODO: we need to fetch paginated data from the backend here.
    // Currently pagination is not implemented in the backend, and the endpoint returns all the teammate.
    this.currentPage++;
    taskFor(this.fetchTeammates).perform();
  }

  @task({ enqueue: true })
  *fetchTeammates(): TaskGenerator<void> {
    if (!this.args.teammatesTableData) {
      return;
    }
    this.tableIsLoadingMore = true;
    let teammatesData = yield this.getTeammates();
    this.localData = [...this.localData, ...teammatesData];
    if (this.localData.length === this.args.teammatesTableData.length) {
      this.lastPageHit = true;
    }
    this.tableIsLoadingMore = false;
  }

  async getAdmin(id: string) {
    return await this.inboxApi.fetchInbox(InboxCategory.Admin, id);
  }

  async getTeammates() {
    let paginatedData = this.args.teammatesTableData.slice(
      (this.currentPage - 1) * this.pageSize,
      this.currentPage * this.pageSize,
    );
    return await Promise.all(
      paginatedData.map(
        async (element: {
          id: string;
          status_code: number;
          away_status_reason: string;
          twilio_device_presence: boolean;
        }) => {
          let admin = await this.getAdmin(element.id);

          return {
            id: admin.id,
            admin,
            name: admin.name,
            status: TEAMMATE_ACTIVITY_STATUS_CODE_MAP.get(element.status_code),
            statusReason: element.away_status_reason,
            twilioDevicePresence: element.twilio_device_presence,
          };
        },
      ),
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::Drilldown::PhoneAvailableTeammates': typeof PhoneAvailableTeammates;
    'inbox2/dashboard/drilldown/phone-available-teammates': typeof PhoneAvailableTeammates;
  }
}
